import { Component, OnInit, PLATFORM_ID } from '@angular/core';
import { AuthenticationService } from 'sostereo-services';
import { TrackingService } from '../../angular/shared/services/tracking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { clone } from 'lodash-es';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  template: '<app-full-page-loader></app-full-page-loader>',
})
export class RedirectionComponent implements OnInit {
  private $versionUpdatesSW: Subscription;
  constructor(
    private trackingService: TrackingService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private swUpdate: SwUpdate,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    // This timeout is to avoid an ExpressionChangedAfterItHasBeenCheckedError.
    setTimeout(() => {
      if (this.router.url.includes('/auth/')) {
        const authToken = this.route.snapshot.paramMap.get('authToken');
        this.authenticationService.getPendingUserByToken(authToken).subscribe(
          (aRes) => {
            const userData = aRes.data;
            this.authenticationService.setCurrentUser(userData);
            this.trackingService.setIdentity(userData, 'login', true);
            this.trackingService.track(
              'Login Success',
              {
                userId: userData.id,
                userEmail: userData.email,
                userData: userData,
              },
              {
                event_action: 'Login',
                event_type: 'Authentication',
                event_user_email: userData?.email,
              },
            );
            let queryParams = clone(this.route.snapshot.queryParams);
            delete queryParams.returnUrl;
            delete queryParams.goTo;
            const newUrl = decodeURIComponent(
              this.route.snapshot.queryParams.returnUrl ||
                this.route.snapshot.queryParams.goTo ||
                '/',
            );
            this.router.navigate([newUrl], {
              queryParams: queryParams,
            });
          },
          () => this.router.navigate(['/login']),
        );
      } else {
        const formattedUrl = decodeURIComponent(this.router.url);
        if (this.router.url !== formattedUrl) {
          window.location.href = formattedUrl;
        } else if (this.router.url === '/brands') {
          this.router.navigate(['/stereobrands']);
        } else {
          this.checkForUpdate();
        }
      }
    });
  }

  public checkForUpdate() {
    if (!this.$versionUpdatesSW) {
      this.$versionUpdatesSW = this.swUpdate.versionUpdates.subscribe((value: VersionEvent) => {
        if (value.type === 'VERSION_DETECTED') {
          console.log('swUpdate >>>> ', value);
          window.location.reload();
        } else {
          console.log('False swUpdate >>> ');
          this.$versionUpdatesSW.unsubscribe();
        }
      });
    }

    if (isPlatformBrowser(PLATFORM_ID) && 'serviceWorker' in navigator) {
      console.log('True new Found >>> ');
      this.swUpdate
        .checkForUpdate()
        .then((evt) => {
          setTimeout(() => {
            this.router.navigate(['/not-found']);
          }, 5000);
          console.log('Not found 1', evt);
        })
        .catch((err) => {
          console.log('Not found 2', err);
          this.router.navigate(['/not-found']);
        });
    } else {
      console.log('Else Not Found >>> ');
      this.router.navigate(['/not-found']);
    }
  }
}
