<section id="faq">
  <div class="section-content pt0 sidenav-padding">
    <div class="main-content" style="overflow: auto">
      <div class="pl0 pr0 pb0 container-without-bg mt100">
        <div>
          <div class="row ml0 mr0">
            <div class="col-md-11 col-md-offset-1">
              <img class="logo-sm" src="./assets/images/logo.svg"/>
            </div>
          </div>
          <div class="row ml0 mr0 pb20">
            <div class="col-md-8 col-md-offset-2">
              <h1>Privacy Policy</h1><br/>
              <h6 class="color-secondary-1">EFFECTIVE DATE: February 6, 2015</h6>
              <p>SoStereo Music Group LLC (&#34;SoStereo&#34;, &#34;we&#34; or &#34;us&#34;) respects the privacy wishes
                of its users, recognizes the importance of privacy, is committed to protecting your privacy, and has
                developed and will follow this Privacy Policy as part of such commitment. In this Privacy Policy (&#34;Policy&#34;),
                we describe how we collect, use, and disclose information that we obtain about visitors to our website
                http://www.sostereo.com (the &#34;Site&#34;), and the services available through our Site (collectively,
                the &#34;Services&#34;).</p>
              <p>By accessing and/or using (in any manner whatsoever, regardless of the type of activity, time spent on
                the website, or level of satisfaction) our website and/or the information, services, and/or mechanisms
                contained thereon, you, as a &#34;User&#34;, signify and verify that you have read, understood and agree
                to be bound by the Company Terms of Use and this Privacy Policy, any of which may be revised, updated,
                or amended without notice (which such revised versions shall be made available on our website). Your
                continued or renewed use of SoStereo after any such revisions will verify your acceptance of the new
                agreements.</p>
              <p>Further, by visiting our Site or using any of our Services, you agree that your personal information
                will be handled as described in this Policy. Your use of our Site or Services, and any dispute over
                privacy, is subject to this Policy and our Terms of Use, including its applicable limitations on damages
                and the resolution of disputes. Our Terms of Use are incorporated by reference into this Policy and we
                encourage you to visit or revisit that important document.</p>
              <h6 class="color-secondary-1">Information We Collect About You</h6>
              <p>Using various technologies, which may include, but are not limited to, cookies, we, and third parties
                may collect personal information about you that may or may not be able to identify you, as well as
                information about your activities on our site. We may collect such information about you directly from
                you and from third parties, as well as automatically through your use of our Site or Services.</p>
              <p>1) Information We Collect Directly From You. All visitors to our Site may browse the Site without
                registering for our Services. To make purchases on our Site, however, you must register by providing
                your first name, last name, contact email and company (optional), which information will stored and used
                internally. We also may collect and store additional optional information from you, including your
                gender and position, though you are not required to provide us with this information. We will also
                collect and store information that you voluntarily provide to us, such as your name (e.g., if you
                contact us with a question) or information that you provide to us in a survey or questionnaire. If you
                chose to connect your account on our Site to any social media account (e.g. Twitter or Facebook) (&#34;Social
                Media Accounts&#34;), we also may collect additional information from you, which information is gathered
                from your use of these Social Media Accounts and the information you provide on these Social Media
                Accounts.</p>
              <p>2) Information We Collect Automatically. We may automatically collect and store information, such as
                the following: information about your use of our Services (on our Site) through cookies and other
                technologies: your domain name; your browser type; operating system type; device name and model; pages
                or screens you view; links you click; your IP address; the length of time you visit or use our Services;
                and the referring URL, or the webpage that led you to our Site. We may combine information collected
                automatically with other information that we have collected about you.</p>
              <h6 class="color-secondary-1">How We Use Your Information</h6>
              <p>We use your information, including your personal information, for the following purposes:</p>
              <p>• To provide our Services to you; to communicate with you about your use of our Services; to respond to
                your inquiries; and for other customer service purposes.</p>
              <p>• To better understand how users access and use our Service, both on an aggregated and individualized
                basis; to respond to user desires and preferences; and for other research and analytical purposes.</p>
              <p>• To tailor the content and information that we may send or display to you; to offer personalized help
                and instructions; and to otherwise personalize your experiences while using our Site or Services.</p>
              <p>• To provide you with news and newsletters, special offers, and promotions; to contact you about
                products or information we think may interest you; and for other marketing, advertising and promotional
                purposes.</p>
              <p>• To comply with applicable legal obligations.</p>
              <h6 class="color-secondary-1">How We Share Your Information</h6>
              <p>We may share your information, including personal information, as follows:</p>
              <p>• Twitter and Other Social Media Followers. If you manually select to share your information on another
                social media platform, such as Twitter, we will share your information with that platform. Affiliates.
                We may disclose your information to our affiliates or subsidiaries for research, marketing and other
                purposes consistent with this Policy.</p>
              <p>• Service Providers. We may disclose your information to vendors, service providers, contractors,
                agents, or other entities who perform functions on our behalf.</p>
              <p>• Business Transfers. If we are acquired by or merged with another company, if substantially all of our
                assets are transferred to another company or as part of a bankruptcy proceeding, we may transfer your
                information to another entity.</p>
              <p>• In Response to Legal Process. We may disclose your information in order to comply with the law, a
                judicial proceeding, court order or other legal process, such as in response to a court order or a
                subpoena.</p>
              <p>• To Protect Us and Others. We also may disclose your information where we believe it is necessary to
                investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving
                potential threats to the safety of any person, violations of our Terms of Service or this Policy or as
                evidence in litigation in which we are involved.</p>
              <h6 class="color-secondary-1">Cookies and Other Tracking Mechanisms</h6>
              <p>We and our service providers use cookies and other tracking mechanisms to track information about your
                use of our Site or Services. We or our service providers may combine this information with other
                personal information we collect from you.</p>
              <h6>Do Not Track. Our Site does not respond to Do Not Track signals.</h6>
              <p>However, we do not track your activities once you leave our Site. You may, however, disable certain
                tracking as discussed in this section (e.g., by disabling cookies).</p>
              <h6>Cookies.</h6>
              <p>We or our service providers may use cookies to track visitor activity on our Site. A cookie is a text
                file that a website transfers to your computer’s hard drive for record-keeping purposes. We or our
                service providers may use cookies to track user activities on our Site, such as the pages visited and
                time spent on our Site. Most browsers allow users to refuse cookies. The Help portion of the toolbar on
                most browsers will tell you how to prevent your computer from accepting new cookies, how to have the
                browser notify you when you receive a new cookie, or how to disable cookies altogether. Visitors to our
                Site who disable cookies may not be able to browse certain areas of the Site.</p>
              <h6>Local Storage Objects.</h6>
              <p>We may use Flash Local Storage Objects (&#34;Flash LSOs&#34;) to store your Site preferences and to
                personalize your visit. Flash LSOs are different from browser cookies because of the amount and type of
                data stored. Typically, you cannot control, delete or disable the acceptance of Flash LSOs through your
                web browser. For more information on Flash LSOs, or to learn how to manage your settings for Flash LSOs,
                go to the Adobe Flash Player Help Page, choose &#34;Global Storage Settings Panel&#34; and follow the
                instructions. To see the Flash LSOs currently on your computer, choose &#34;Website Storage Settings
                Panel&#34; and follow the instructions to review and, if you choose, to delete any specific Flash
                LSO.</p>
              <h6>Clear GIFs, pixel tags and other technologies.</h6>
              <p>Clear GIFs are tiny graphics with a unique identifier, similar in function to cookies. In contrast to
                cookies, which are stored on your computer’s hard drive, clear GIFs are embedded invisibly on web pages.
                We may use clear GIFs (also known as web beacons, web bugs or pixel tags), in connection with our Site
                to, among other things, track the activities of Site visitors, help us manage content and compile
                statistics about Site usage. We or our service providers may also use clear GIFs in HTML emails to our
                customers to help us track email response rates, identify when our emails are viewed and track whether
                our emails are forwarded.</p>
              <h6>Third-Party Analytics.</h6>
              <p>We or our service providers use automated devices and applications to evaluate use of our Site and App.
                We also may use other analytic means to evaluate our Services. We or our service providers use these
                tools to help us improve our Site, Services, performance and user experiences. These entities may use
                cookies and other tracking technologies to perform their services.</p>
              <p>For further information go to our <a class="color-secondary-1" ui-sref="main.views.cookies-policy"
                                                      target="_top">cookies policy</a></p>
              <h6 class="color-secondary-1">Security of My Personal Information</h6>
              <p>We have implemented appropriate security measures to protect the personal information we collect from
                unauthorized use, access, or disclosure and we will use best efforts to comply with all laws and
                regulations. Please be aware that despite our best efforts, no data security measures can guarantee 100%
                security. You should take steps to protect against unauthorized access to your password, mobile device
                and computer by, among other things, signing off after using a shared computer, choosing a robust
                password that nobody else knows or can easily guess, and keeping your log-in and password private. We
                are not responsible for any lost, stolen or compromised passwords or for any activity on your account
                via unauthorized password activity. (Any third parties to whom personal information is disclosed may
                have their own privacy policies which govern their use of such personal information and their practices
                are not covered by this agreement. There may be instances on or through our website where you may access
                other websites, through clicking on advertisements or otherwise, that do not operate under our control,
                terms of use, or privacy policy.)</p>
              <h6 class="color-secondary-1">Access to My Personal Information</h6>
              <p>You may modify personal information that you have submitted by logging into your account and updating
                your profile information in your account settings on our Site. Please note that copies of information
                that you have updated, modified or deleted may remain viewable in cached and archived pages of the Site
                for a period of time.</p>
              <h6 class="color-secondary-1">Marketing Communications</h6>
              <p>We may send periodic promotional or informational emails to you. You may opt out of such communications
                by following the opt-out instructions contained in the email or by emailing us at <a
                    class="color-secondary-1" href="mailto:support@sostereomusic.com" target="_top">support&#64;sostereomusic.com</a>.
                Please note that it may take up to 10 business days for us to process opt-out requests. If you opt out
                of receiving emails about recommendations or other information we think may interest you, we may still
                send you emails about your account or any services you have requested or received from us.</p>
              <h6 class="color-secondary-1">Children Under 13</h6>
              <p>Our Services are not designed for children under 13. If we discover that a child under 13 has provided
                us with personal information, we will delete such information from our systems. We will not knowingly
                collect or store data or personal information from anyone under the age of 13.</p>
              <h6 class="color-secondary-1">Contact Us</h6>
              <p>If you have questions about the privacy aspects of our Services or would like to make a complaint,
                please contact us at <a class="color-secondary-1" href="mailto:support@sostereomusic.com" target="_top">
                  support&#64;sostereomusic.com</a>
              </p>
              <h6 class="color-secondary-1">Changes to this Policy</h6>
              <p>This Policy is current as of the Effective Date set forth above. We may change this Policy from time to
                time, so please be sure to check back periodically. We will post any changes to this Policy on
                sostereo.com. If we make any changes to this Policy that materially affect our practices with regard to
                the personal information we have previously collected from you, we will endeavor to provide you with
                notice in advance of such change by highlighting the change on our Site.</p>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</section>
