<section id="faq">
  <div class="section-content pt0 sidenav-padding">
    <div class="main-content" style="overflow: auto">
      <div class="pl0 pr0 pb0 container-without-bg mt100">
        <div>
          <div class="row ml0 mr0">
            <div class="col-md-11 col-md-offset-1">
              <img class="logo-sm" src="./assets/images/logo.svg"/>
            </div>
          </div>
          <div class="row ml0 mr0 pb20">
            <div class="col-md-8 col-md-offset-2">
              <h1>Cookies Policy</h1>
              <br/>
              <p>Cookies help us get data that will improve your experience in SoStereo. None of the data is sold to any
                third party.</p><br/>
              <h6 class="color-secondary-1">what are cookies?</h6>
              <p>Cookies are small text files that can be used by websites to make a user's experience more efficient. .
                You consent to our cookies if you continue to use our website.</p><br/>
              <h6 class="color-secondary-1">What type of cookies does SoStereo use?</h6>
              <p>We use session-based and some third party cookies, like Google Analytics on the domains operated by
                SoStereo.</p><br/>
              <h6 class="color-secondary-1">Why do we use cookies?</h6>
              <p>We use cookies to improve and customize SoStereo Services and your experience; to know what are your
                favorite features of our platform and to give a better place to find the best music each day</p>
              <ul class="pl20">
                <li><b>Marketing: </b>We use AdRoll to place anonymous retargeting "cookies" in the user's browser to
                  display retargeting ads as they visit other
                  sites (AdRoll <a class="color-secondary-1" href="https://www.adrollgroup.com/en-IE/privacy"
                                   target="_blank">Privacy Policy</a>)
                </li>
                <br/>
                <li><b>Performance, Analytics and Research: </b>The services contained in this section enable SoStereo
                  to monitor and analyze web traffic and can be used to keep
                  track of User behavior. All the third party tools we use anonymize IP addresses and do not track
                  personal information
                  - these include: Google Analytics (<a class="color-secondary-1"
                                                        href="https://policies.google.com/privacy?hl=policies"
                                                        target="_blank">Privacy Policy</a>), Mixpanel (<a
                      class="color-secondary-1" href="https://mixpanel.com/legal/privacy-policy/" target="_blank">Privacy
                    Policy</a>) and Hotjar (<a class="color-secondary-1"
                                               href="https://www.hotjar.com/legal/policies/privacy" target="_blank">Privacy
                    Policy</a>).
                </li>
              </ul>
              <br/>
              <h6 class="color-secondary-1">What information we collect?</h6>
              <ul class="pl20">
                <li><b>Information you give us. </b>Information you give us may include your name and email address. We
                  only collect this information when you voluntarily give it to us. You can always refuse to offer any
                  information, but it may prevent you from using some aspects of the site.
                </li>
                <br/>
                <li><b>Information we collect about you. </b>This is information that we collect automatically about
                  your visit during your time on the Site. It typically involves technical information including the
                  browser name, the type of computer, and technical information about User's means of connection to our
                  Site, such as the operating system, IP Address, and the Internet service providers utilized and other
                  similar information. This type of information is typically collected using small data files called
                  cookies. This information helps us to provide you with a good experience when you browse the Site and
                  also to indicate where the Site requires improvement.
                </li>
                <br/>
                <li><b>Information we receive from other sources. </b>This includes information we receive about you
                  from other websites we operate. The information in these cases will be gathered voluntarily with your
                  consent.
                </li>
              </ul>
              <br/>
              <h6 class="color-secondary-1">How we use collected information?</h6>
              <p>The information SoStereo collects from you may be used by us for the following purposes:</p>
              <ul class="pl20">
                <li>To improve our Site</li>
                <li>To improve our customer service by responding to your requests more quickly and efficiently</li>
                <li>To send emails</li>
                <li>To personalize your experience on the site</li>
                <li>To complete an order placed on the site, using information provided about yourself. We do not share
                  this information with outside parties, unless needed to complete the order
                </li>
                <li>To send you information you agreed to receive about topics we think will be of interest to them</li>
                <li>To process payments</li>
              </ul>
              <br/>
              <h6 class="color-secondary-1">where can I find them?</h6>
              <p>In addition to what is specified in this document, you can manage your preferences for Cookies directly
                from within your browser and prevent,for example, third parties from installing them.</p><br/>
              <h6 class="color-secondary-1">Contact Us</h6>
              <p>If you have any other questions about our cookie handling practices, please contact us at <a
                  class="color-secondary-1" href="mailto:support@sostereomusic.com" target="_top">support&#64;sostereomusic.com</a>
              </p>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</section>
