<div *ngFor="let segment of segments; let i = index">
  <div
    class="commentContent commentListSection comment-thumbnail border-radius"
    [style.left.px]="segment.left"
    style="bottom: 100px"
    *ngIf="segment.showSegmentDetails"
  >
    <a (click)="closeSegmentDetails(segment)">
      <i class="fa fa-times pull-right commentActionBtn" aria-hidden="true"></i>
    </a>
    <!-- Time -->
    <div class="col-xs-12 col-sm-12 mb10 pl0 d-flex align-items-center">
      <div class="pr5">
        <span
          class="ss-icon-play_on"
          style="font-size: 25px; color: white"
          (click)="playSegment(segment)"
        ></span>
      </div>
      <div class="col-xs-8 col-sm-8 artist-top-margin pl5">
        <p class="mb5" style="">Play Segment</p>
        <h4 class="now-playing">
          {{ segment.start | wavesurferTimeFormat }} - {{ segment.endTime | wavesurferTimeFormat }}
        </h4>
      </div>
      <div class="col-xs-2 pl0">
        <!-- Next comment btn -->
        <!-- <button class="btn sm-btn primary-btn" style="margin: -5px 0 0 -35px;border-width: 1px;border-color: #fff;"
          (click)="goToNextComment(i)"> Next
        </button> -->
      </div>
    </div>
    <!-- Comments -->
    <div class="commentList comment-scroll custom-scroll-bar pl5">
      <!-- Comment -->
      <p *ngIf="segment.percentage">
        {{ segment.percentage }}% match with reference segment from
        {{ segment.refStart | wavesurferTimeFormat }} to {{ segment.refEnd | wavesurferTimeFormat }}
      </p>
      <!-- {{similarSongRef.segmentsGroups[segment.refSegment].selected_segment.start}} -->
      <div>
        <span class="d-flex align-items-center justify-content-space-between" style="width: 100%">
          <button class="btn outline-primary-btn mr10" (click)="similarSegment(segment)">
            Search similar to this segment
          </button>
        </span>
      </div>
    </div>
  </div>
  <a
    (click)="toggleSegmentDetails(segment, $index)"
    *ngIf="segment.hasOwnProperty('left') && segment.width"
  >
    <i class="commentBubble fa fa-bookmark" [style.left.px]="segment.left" style="font-size: 15px">
      <!-- <p style="font-size: 8px;left:4px;" *ngIf="segment.duration > 10">{{segment.percentage}}%</p> -->
    </i>
  </a>
  <div
    (click)="toggleSegmentDetails(segment)"
    class="timeArea pt0"
    [style.left.px]="segment.left"
    *ngIf="segment.hasOwnProperty('left') && segment.width && segment.percentage"
    [style.width.px]="segment.width"
    [style.opacity]="segment.opacity"
    style="position: absolute"
  >
    <span class="segment-percentage" *ngIf="segment.percentage && segment.duration > 10"
      >{{ segment.percentage }}%</span
    >
  </div>
</div>
