import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SeoService } from './angular/shared/services/seo.service';
import { ErrorHandlingService } from './angular/shared/services/error-handling.service';
import { SharedModule } from './angular/shared/shared.module';
import { ModalsModule } from './angular/modules/modals/modals.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { SostereoServicesModule } from 'sostereo-services';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieConsentComponent } from './angular/layout/components/cookie-consent/cookie-consent.component';
import {
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  provideHttpClient,
  withFetch,
} from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptor/http-config.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { SpacesHeaderComponent } from './angular/layout/components/spaces-header/spaces-header.component';
import { PlayerWidgetComponent } from './angular/layout/components/player-widget/player-widget.component';
import { NewCommentComponent } from './angular/layout/components/player-widget/new-comment/new-comment.component';
import { CommentListComponent } from './angular/layout/components/player-widget/comment-list/comment-list.component';
import { AudioAnalysisComponent } from './angular/layout/components/player-widget/audio-analysis/audio-analysis.component';
import { NewSegmentComponent } from './angular/layout/components/player-widget/new-segment/new-segment.component';
import { SharedLayoutModule } from './angular/modules/shared-layout/shared-layout.module';
import { MainHeaderComponent } from './angular/layout/main-header/main-header.component';
import { MobileFooterComponent } from './angular/layout/mobile-footer/mobile-footer.component';
import { CurrencyPipe } from '@angular/common';
import { RedirectionComponent } from './components/redirection/redirection.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { TechnicalIssuesComponent } from './components/technical-issues/technical-issues.component';
import { FaqComponent } from './components/faq/faq.component';
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SessionButtonsComponent } from './angular/layout/components/session-buttons/session-buttons.component';
import { ClipboardModule } from 'ngx-clipboard';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import packageJson from 'package.json';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSliderModule } from 'ngx-slider-v2';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { LoadingComponent } from './components/loading/loading.component';
import { CatalogOwnerCommunicationModalComponent } from './angular/modules/modals/components/catalog-owner-communication-modal/catalog-owner-communication-modal.component';
import { FooterComponent } from './angular/layout/components/footer/footer.component';
import { RouteReuseStrategy } from '@angular/router';
import { AppRouteReuseStrategy } from './url-handling/app-route-reuse-strategy';

const version: string = packageJson?.version;

Sentry.init({
  dsn: environment.sentryDsn,
  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  environment: environment.production ? 'production' : 'staging',
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  release: version,
});

@NgModule({
  declarations: [
    AppComponent,
    MainHeaderComponent,
    MobileFooterComponent,
    CookieConsentComponent,
    SpacesHeaderComponent,
    PlayerWidgetComponent,
    NewCommentComponent,
    CommentListComponent,
    AudioAnalysisComponent,
    NewSegmentComponent,
    RedirectionComponent,
    FaqComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    CookiesPolicyComponent,
    SessionButtonsComponent,
    MobileFooterComponent,
    TechnicalIssuesComponent,
    LoadingComponent,
  ],
  imports: [
    CatalogOwnerCommunicationModalComponent,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SostereoServicesModule.forRoot(environment),
    ModalModule.forRoot(),
    ModalsModule,
    AppRoutingModule,
    SharedModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    SharedLayoutModule,
    HttpClientJsonpModule,
    TooltipModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      registrationStrategy: 'registerImmediately',
    }),
    ClipboardModule,
    NgxSliderModule,
    FooterComponent,
    NotFoundComponent,
  ],
  providers: [
    DeviceDetectorService,
    SeoService,
    ErrorHandlingService,
    CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: false,
        filter: (req) => !req.url.includes('api'),
      }),
    ),
    provideHttpClient(withFetch()),
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // constructor(trace: TraceService) {}
}
