import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthenticationService } from 'sostereo-services';
import { TrackingService } from '../../../shared/services/tracking.service';
import { CommonService } from '../../../shared/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-spaces-header',
  templateUrl: './spaces-header.component.html',
  styleUrls: ['./spaces-header.component.scss'],
})
export class SpacesHeaderComponent implements OnInit {
  public loggedUser: any;
  public subdomain = this.commonService.getSubdomain();

  constructor(
    private trackingService: TrackingService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private renderer: Renderer2,
  ) {
    this.loggedUser = this.authenticationService.getCurrentUser();
    router.events.subscribe(() => {
      if (!this.authenticationService.getCurrentUser()) {
        setTimeout(() => {
          const header = document.getElementById('spaces-header');
          if (header && this.location.path().includes('discover')) {
            this.renderer.addClass(header, 'scrolled');
          } else if (header && header.classList.contains('scrolled')) {
            this.renderer.removeClass(header, 'scrolled');
          }
        });
      }
    });
    this.authenticationService.accessData.subscribe((user) => {
      this.loggedUser = user;
    });
  }

  ngOnInit() {
    const redirect = this.route.snapshot.queryParams.returnUrl;
    if (redirect) {
      this.login(redirect);
    }

    window.addEventListener(
      'scroll',
      (e: any) => {
        const header = document.getElementById('spaces-header');
        if (header && !this.location.path().includes('discover')) {
          if (e.srcElement.scrollTop > 100) {
            this.renderer.addClass(header, 'scrolled');
          } else {
            this.renderer.removeClass(header, 'scrolled');
          }
        }
      },
      true,
    );
  }

  goTo(route) {
    if (this.router.url.indexOf('/discover') !== -1) {
      document.getElementById(route.replace('/#', '')).scrollIntoView();
    } else {
      this.router.navigateByUrl(route);
    }
  }

  login(redirect?: string) {
    const params: any = redirect ? { queryParams: { returnUrl: redirect } } : {};
    this.router.navigateByUrl('/login', params);
  }

  signUp() {
    this.router.navigateByUrl('/signup');
  }
}
