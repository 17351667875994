<div class="cookies-container">
  <div class="bckg-cookiesData cookiesData">
    <p class="mb0">
      This website uses cookies to ensure you get the best experience.
      <a [routerLink]="'/cookies-policy'" class="cookieLearn">
        Learn more about our Cookies Policy
      </a>
    </p>
    <button class="btn sm-btn primary-btn mt10 mb10" (click)="addConsentCookie()">
      Ok, got it!
    </button>
  </div>
</div>
