import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { sortBy } from 'lodash-es';
import { SimilarSongService } from 'src/app/angular/shared/services/similar-song.service';

@Component({
  selector: 'app-audio-analysis',
  templateUrl: './audio-analysis.component.html',
  styleUrls: ['./audio-analysis.component.scss'],
})
export class AudioAnalysisComponent implements OnInit, OnChanges {
  @Input() loggedUser;
  @Input() segments: any[];
  @Input() rating: {};
  @Input() duration: any;
  @Input() track;
  @Output() segmentRequest = new EventEmitter();
  @Output() updateCommentRequest = new EventEmitter();
  @Output() loginRequest = new EventEmitter();
  private message = '';
  public openComment: any;
  public similarSongRef: any;

  constructor(private similarSongService: SimilarSongService) {}

  ngOnInit() {
    console.log('init AudioAnalysisComponent');
  }

  ngOnChanges() {
    if (this.track && Reflect.has(this.track, 'segmentsGroups')) {
      console.log('init audio analysis', this.track);
      this.similarSongRef = JSON.parse(localStorage.getItem('SOSTEREO.similarSongRef'));
      if (this.similarSongRef) {
        const waveFormContainer = document.getElementById('waveform-container');
        const groups = Object.keys(this.track.segmentsGroups);
        this.segments = groups
          .map((group) => {
            const rating =
              this.track.rating && this.track.rating.segmentsScore[group]
                ? this.track.rating.segmentsScore[group]
                : {};
            return this.track.segmentsGroups[group].segments.map((seg) => {
              const left = Math.trunc((seg.start * waveFormContainer.offsetWidth) / this.duration);
              const rightPosition = Math.trunc(
                ((seg.start + seg.duration) * waveFormContainer.offsetWidth) / this.duration,
              );
              if (rating.percentage) {
                rating.percentage = rating.percentage ? Math.trunc(rating.percentage) : 0;
                // Temporal workaround ... var selectedSegment comes different from yt refs....
                const refSegments: any = this.similarSongRef.segmentsGroups
                  ? this.similarSongRef.segmentsGroups[parseInt(rating.refSegment)]
                  : this.similarSongRef.versions.match.segmentsGroups[parseInt(rating.refSegment)];

                const useKey = refSegments.hasOwnProperty('selected_segment')
                  ? 'selected_segment'
                  : 'selectedSegment';
                rating.refStart = refSegments[useKey].start;
                rating.refEnd = rating.refStart + refSegments[useKey].duration;
              }
              return {
                group: group,
                ...rating,
                ...seg,
                left: left,
                opacity: Math.pow(2, rating.percentage / 100) - 1.4,
                width: rightPosition - left,
                endTime: seg.start + seg.duration,
              };
            });
          })
          .reduce((acc, currentGroup) => acc.concat(currentGroup));
        this.segments = sortBy(this.segments, 'start');
        console.log('segs ', this.segments);
      }
    }
  }

  setSegmentsPosition() {
    const waveFormContainer = document.getElementById('waveform-container');
    // let groups = this.track.objectData
    this.segments.forEach((seg) => {
      seg.showSegmentDetails = true;
      seg.left = (seg.start * waveFormContainer.offsetWidth) / this.duration;
      seg.left = Math.trunc(seg.left);
      let rightPosition =
        ((seg.start + seg.duration) * waveFormContainer.offsetWidth) / this.duration;
      rightPosition = Math.trunc(rightPosition);
      seg.width = rightPosition - seg.left;
    });
  }

  login() {
    this.loginRequest.emit();
  }

  playSegment(segment) {
    const seg = { startTime: parseInt(segment.start, 10), endTime: parseInt(segment.endTime, 10) };
    this.segmentRequest.emit(seg);
  }

  // goToNextComment(commentIndex) {
  //   const comment = this.commentItems[commentIndex + 1] ? this.commentItems[commentIndex + 1] : this.commentItems[0];
  //   this.toggleComments(comment);
  //   this.playSegment(comment);
  // }

  toggleMoreOptions(comment) {
    comment.showMoreOptions = !comment.showMoreOptions;
  }

  toggleSegmentDetails(segment, index) {
    console.log('toggleSegmentDetails ', segment, index);
    this.segments.forEach((c) => {
      if (JSON.stringify(c) !== JSON.stringify(segment)) {
        c.showSegmentDetails = false;
      }
    });
    segment.showSegmentDetails = !segment.showSegmentDetails;
    this.openComment = segment.showSegmentDetails ? segment : null;
  }

  closeSegmentDetails(segment) {
    segment.showSegmentDetails = false;
  }

  public similarSegment(segment) {
    console.log('segment ', segment, this.track, this.track.trackId);
    const songData = this.similarSongService.setStorageSimilarSong(this.track);
    this.similarSongRef = songData;
    if (this.track.objectData.seed_youtube) {
      this.similarSongService.goToYtSegment(this.track, segment);
    } else {
      this.similarSongService.goToRefSegment(this.track, segment);
    }
  }

  onKeydown(event) {
    event.stopPropagation();
  }
}
