import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PlayerWidgetService } from '../../../services/player-widget.service';
import { Router } from '@angular/router';
import { SearchTagsService } from '../../../../shared/services/search-tags.service';
import { WavesurferTimeFormatPipe } from '../../../../shared/pipes/wavesurfer-time-format.pipe';
import { SimilarSongService } from 'src/app/angular/shared/services/similar-song.service';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';

declare let $: any;

@Component({
  selector: 'app-new-segment',
  templateUrl: './new-segment.component.html',
  styleUrls: ['./new-segment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewSegmentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() loggedUser;
  @Input() track;
  @Input() control;
  @Input() showSelectSegment: boolean;
  @Output() closeModal = new EventEmitter();
  private segment: {
    startTime: number;
    endTime: number;
  };

  public showNewSegmentPopUp: boolean;
  public message: string;
  public offsetLeft: number;
  private newTrack$: Subscription = new Subscription();

  constructor(
    private searchTagsService: SearchTagsService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private playerWidgetService: PlayerWidgetService,
    private router: Router,
    private wavesurferTimeFormatPipe: WavesurferTimeFormatPipe,
    private changeDetectorRef: ChangeDetectorRef,
    private similarSongService: SimilarSongService,
    private trackingService: TrackingService,
  ) {
    this.offsetLeft = 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.showSelectSegment?.currentValue ||
      (changes.track?.currentValue._id !== this.track._id && this.showSelectSegment)
    ) {
      this.showNewSegmentPopUp = true;
      const waveformContainer = $('#waveform-container');
      this.addSegment({ offsetX: (20 * waveformContainer.width()) / this.control.duration });
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    const waveformContainer = document.getElementById('waveform-container');
    const resizable = document.getElementById('resizable');
    if (waveformContainer && resizable) {
      this.renderer.removeChild(waveformContainer, resizable);
    }
    this.newTrack$.unsubscribe();
    this.closeModal.emit(this.playerWidgetService.showSelectSegment);
    this.changeDetectorRef.detectChanges();
  }

  toggleSegmentIcon(event?) {
    const icon = document.getElementsByClassName('segmentIconContent')[0];
    if (event) {
      this.renderer.setStyle(icon, 'left', `${(event.offsetX - 7).toString()}px`);
      this.renderer.setStyle(icon, 'display', 'block');
    } else {
      this.renderer.setStyle(icon, 'display', 'none');
    }
    this.changeDetectorRef.detectChanges();
  }

  public addSegment(event) {
    const waveformContainer = $('#waveform-container');
    const resizable = document.getElementById('resizable');
    if (waveformContainer && resizable) {
      this.renderer.removeChild(waveformContainer, resizable);
    }
    this.showNewSegmentPopUp = true;
    let xPosition;
    if (event.offsetX > waveformContainer.width() * 0.92) {
      xPosition = waveformContainer.width() * 0.92;
    } else {
      xPosition = event.offsetX;
    }

    this.offsetLeft = xPosition;
    const widthResizable = this.showSelectSegment
      ? (35 * waveformContainer.width()) / this.control.duration - xPosition
      : waveformContainer.width() * 0.1;
    const div = $(`<div id="resizable">
        <span class="material-icons-outlined arrows ui-resizable-e" style="right:-9px;">swap_horiz</span>
        <span class="material-icons-outlined arrows ui-resizable-w" style="left:-8px;">swap_horiz</span>
        </div>`).css({
      position: 'absolute',
      left: xPosition,
      bottom: 0,
      'z-index': 5,
      width: widthResizable,
    });
    waveformContainer.append(div);
    setTimeout(() => {
      $('#resizable').resizable({
        resize: (eventR, ui) => {
          console.log(eventR, ui);
          this.updateSegmentSection();
        },
        containment: '#waveform-container',
        maxHeight: 70,
        minHeight: 70,
        minWidth: 40,
        handles: { e: '.ui-resizable-e', w: '.ui-resizable-w' },
      });
    }, 1000);

    this.segment = {
      startTime: (xPosition * this.control.duration) / waveformContainer.width(),
      endTime:
        Math.round((widthResizable + xPosition) * this.control.duration) /
        waveformContainer.width(),
    };
    this.changeDetectorRef.detectChanges();
  }

  updateSegmentSection() {
    const waveformContainer = document.getElementById('waveform-container');
    const resizable = document.getElementById('resizable');
    this.segment = {
      startTime: (resizable.offsetLeft * this.control.duration) / waveformContainer.offsetWidth,
      endTime:
        ((resizable.offsetWidth + resizable.offsetLeft) * this.control.duration) /
        waveformContainer.offsetWidth,
    };
    this.changeDetectorRef.detectChanges();
  }

  public searchForSegment(segment) {
    console.log(segment);
    this.similarSongService.setStorageSimilarSong(this.track);
    if (this.track.objectData.seed_youtube) {
      this.similarSongService.goToYtNewSegment(this.track, this.segment);
    } else {
      this.similarSongService.goToRefNewSegment(this.track, this.segment);
    }
    this.hidePopUpAndSegmentArea();
    this.trackingService.track(
      'Similar Song Segment Search',
      { track: this.track },
      {
        event_action: 'Similar song segment search',
        event_type: 'Search',
        element_type: 'Song',
        element_value: this.track?.name,
        element_id: this.track?.trackId,
      },
    );
  }

  public searchFullTrack() {
    this.similarSongService.setStorageSimilarSong(this.track);
    if (this.track.objectData.seed_youtube) {
      this.similarSongService.goToRefFullTrack(this.track, true);
    } else {
      this.similarSongService.goToRefFullTrack(this.track, false);
    }
    this.hidePopUpAndSegmentArea();
    this.trackingService.track(
      'Similar Song Full Track Search',
      { track: this.track },
      {
        event_action: 'Similar song full track search',
        event_type: 'Search',
        element_type: 'Song',
        element_value: this.track?.name,
        element_id: this.track?.trackId,
      },
    );
  }

  hidePopUpAndSegmentArea() {
    this.segment = {
      startTime: null,
      endTime: null,
    };
    const waveformContainer = document.getElementById('waveform-container');
    const resizable = document.getElementById('resizable');
    if (waveformContainer && resizable) {
      this.renderer.removeChild(waveformContainer, resizable);
      this.showNewSegmentPopUp = false;
      this.message = '';
    }
    this.closeModal.emit(false);
    this.changeDetectorRef.detectChanges();
  }
}
