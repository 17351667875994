<div
  class="header-btns hidden-xs hidden-sm col-sm-offset-0 col-xs-10 col-sm-5 col-md-4 col-lg-4 pull-right pt20"
  *ngIf="!loggedUser && showButtons"
>
  <div class="col sm-8 pull-right">
    <div>
      <button
        *ngIf="!(subdomain && tenantId === 'sostereo')"
        class="btn secondary-btn btn-header"
        (click)="signUp()"
      >
        create an account!
      </button>
    </div>
  </div>
  <div class="col sm-4 pull-right">
    <a class="text-link" style="font-weight: bold" (click)="login()">LOGIN</a>
  </div>
</div>
