import { Component } from '@angular/core';
import { NewsletterFormComponent } from 'src/app/angular/layout/components/newsletter-form/newsletter-form.component';

@Component({
  selector: 'app-newsletter',
  standalone: true,
  imports: [NewsletterFormComponent],
  templateUrl: './newsletter.component.html',
  styleUrl: './newsletter.component.scss',
})
export class NewsletterComponent {}
