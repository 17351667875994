import {
  Component,
  OnInit,
  HostListener,
  Inject,
  PLATFORM_ID,
  ChangeDetectorRef,
} from '@angular/core';
import { TrackingService } from '../../shared/services/tracking.service';
import { AuthenticationService } from 'sostereo-services';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../shared/services/common.service';
import { PlayerService } from '../../shared/services/player.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {
  loggedUser: any;
  subdomain = this.commonService.getSubdomain();
  public currentUrl: string;
  public scrolled = false;
  public tenantId = this.commonService.tenantId;
  private route: ActivatedRoute;
  public company: any;
  public isBrowser: boolean;
  public hideAuthButtons: boolean;
  public activeMobileMenu: boolean = false;
  public isSonicAnalysisUrl = false;

  constructor(
    private trackingService: TrackingService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    public playerService: PlayerService,
    private cd: ChangeDetectorRef,
    @Inject('environment') private environment,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.loggedUser = this.authenticationService.getCurrentUser();
    }
    console.log('loggedUser', this.loggedUser);
    this.currentUrl = this.router.url;
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentUrl = val.url;
        this.isSonicAnalysisUrl = this.router.url.includes('/sonic-analysis') && !this.loggedUser;
      }
    });
    if (this.isBrowser) {
      this.authenticationService.accessData.subscribe((user) => {
        this.loggedUser = user;
      });
    }
    this.commonService.getCompany(this.subdomain, (company) => {
      this.tenantId = this.commonService.tenantId;
      if (company) {
        this.company = company;
      }
    });
  }

  ngOnInit() {
    this.route = this.router.routerState.root;
    const redirect = this.route.snapshot.queryParams.returnUrl;
    const magicToken = this.route.snapshot.queryParams.magicToken;
    if (redirect && !magicToken && this.isBrowser) {
      if (this.authenticationService.getCurrentUser()) {
        const subString = window.location.href.split('returnUrl=');
        this.router.navigateByUrl(
          subString.length > 1 ? decodeURIComponent(subString[1]) : '/discover',
        );
      } else {
        this.subdomainLogin();
      }
    }
  }

  goTo(route, fragment) {
    if ((this.router.url === '/' || this.router.url === '/#our-work') && fragment) {
      document.getElementById(fragment).scrollIntoView();
    } else {
      this.router.navigate([route], { fragment: fragment });
    }
  }

  subdomainLogin(isSignUp?: boolean) {
    const url = this.router.url;
    if (isSignUp) {
      this.router.navigate(['/signup'], { queryParams: { returnUrl: url } });
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: url } });
    }
  }

  public goToBlog() {
    this.trackingService.track(
      'Go to blog',
      { callToAction: 'header' },
      {
        event_action: 'Header section clicked',
        event_type: 'Header Click',
        event_value: 'News',
      },
    );
  }

  private onScroll() {
    if (
      this.playerService.isSmallSize &&
      (this.router.url.includes('/search') || this.router.url.includes('/discover'))
    ) {
      const discoverContainer = document.getElementById('discover');
      this.scrolled = discoverContainer.scrollTop >= 80;
      this.playerService.scrolled.next(this.scrolled);
    }
  }

  @HostListener('window:wheel', ['$event'])
  @HostListener('window:touchmove', ['$event'])
  @HostListener('window:scroll', ['$event'])
  Wheel() {
    //console.log(event);
    window.setTimeout(() => {
      this.onScroll();
    }, 100);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.playerService.isSmallSize = window.innerWidth <= 720;
  }

  public showMainHeader(): boolean {
    return (
      (!this.loggedUser && !this.currentUrl.includes('pitch/')) ||
      this.currentUrl === '/' ||
      this.currentUrl === '/landing' ||
      this.currentUrl === '/submit-brief' ||
      this.currentUrl === '/submit-music' ||
      this.currentUrl === '/#our-work' ||
      this.currentUrl.includes('/our-work') ||
      this.currentUrl === '/#artist' ||
      this.currentUrl === '/who-we-are' ||
      this.currentUrl === '/services'
    );
  }

  public showHomeButton() {
    return (
      this.currentUrl.includes('/services') ||
      this.currentUrl.includes('/our-work') ||
      this.currentUrl.includes('/who-we-are')
    );
  }

  public trackEvent(eventName, action, gaParams) {
    this.activeMobileMenu = false;
    this.trackingService.track(
      eventName,
      {
        action: action,
        params: gaParams,
      },
      {
        ...gaParams,
      },
    );
  }
}
