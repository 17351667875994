<section id="faq">
  <div class="section-content pt0 sidenav-padding">
    <div class="main-content" style="overflow: auto">
      <div class="pl0 pr0 pb0 container-without-bg mt100">
        <div>
          <div class="row ml0 mr0">
            <div class="col-md-11 col-md-offset-1">
              <img class="logo-sm" src="./assets/images/logo.svg"/>
            </div>
          </div>
          <div class="row ml0 mr0 pb20">
            <div class="col-md-8 col-md-offset-2">
              <h1>Sostereo artist page FAQ</h1>
              <br/>
              <h6 class="color-secondary-1">Why work with SoStereo?</h6>
              <p>
                At SoStereo, we’re making it easy for artists to monetize their content while doing what they love -
                making great music. We are
                champions and advocates for independent artists, providing concrete
                opportunities to expand their sync and branded business while earning
                new revenue. We’ve licensed music with brands like Nintendo, Zumba Fitness,
                Netflix and much more.
              </p>
              <h6 class="color-secondary-1">Where is SoStereo based? Who do you work with?</h6>
              <p>
                We are currently based in Miami, Florida. We work with Ad Agencies, TV Networks and Brands across the US
                and Latin America. </p>
              <h6 class="color-secondary-1">How does sync licensing work?</h6>
              <p>
                As soon as you turn your TV you hear music. This can be a commercial, TV show, movie or a video game.
                Licensing is everywhere, but how does it work? When you
                license a song, you are giving the client the right to use your music through a
                specific medium, term, duration and territory. You’ll always get to retain 100%
                of the rights and ownership of your music while doing so.
              </p>
              <h6 class="color-secondary-1">How does SoStereo work with artists?</h6>
              <p>
                We partner up with artists, labels and publishers to expand their sync licensing and brand partnership
                businesses. We sign up artists into our platform & service by getting
                the right to find and execute licensing agreements with third parties on their behalf.
              </p>
              <h6 class="color-secondary-1">
                Can I work with other companies while I work with SoStereo? Is the deal Non-Exclusive? </h6>
              <p>
                Yes! We want you to grow and get more opportunities. Feel free to work with others and expand your
                music's reach. What we do ask is for you to keep
                us posted when there is a song that you’ve placed with an exclusivity section
                with a specific client or brand.
              </p>
              <h6 class="color-secondary-1">Does it cost anything to upload my music into your platform?</h6>
              <p>No, you can request an account and be a part of SoStereo for free.</p>
              <h6 class="color-secondary-1">Is there an agreement I have to sign?</h6>
              <p>
                Yes. Please get in touch with our A&R team(hello&#64;sostereomusic.com) so we can start reviewing your music
                and profile. Once we review what you
                send over we’ll send you an email with next steps.
              </p>
              <h6 class="color-secondary-1">How long is the term of the agreement?</h6>
              <p>The term of this agreement is 2 years.</p>
              <h6 class="color-secondary-1">Do I keep all the rights/ownership of music?</h6>
              <p>
                Yes, you keep 100% of your master and publishing - we just get the right to non-exclusively execute sync
                license agreements with third parties on your behalf.</p>
              <h6 class="color-secondary-1">Does SoStereo get a percentage of the license fee?</h6>
              <p>Yes. We split the all in license fee (Master & Publishing) only on the deals we procure.</p>
              <h6 class="color-secondary-1">Who should sign the agreement?</h6>
              <p>
                The agreement needs to be signed by all the owners of the Master and Publishing. If you only control the
                master but have a publishing deal in place no problem,
                we’ll get in touch with your publisher or counterpart to tell them more about
                our work and get their signature.
              </p>
              <h6 class="color-secondary-1">How do I get started?</h6>
              <p>
                Request an account and send us a sample of your music by visiting
                http://www.sostereo.com/license-your-music. Once you fill out the request
                form our A&R team will review the music and artist profile to ensure that it
                has high quality and is something that can be placed. Upon review one of our A&R
                coordinators will reach out to set you up, sending you the agreement and audio delivery instructions.
              </p>
              <h6 class="color-secondary-1">How does the Audio Delivery work? What type of files should I send?</h6>
              <p>
                Please send over WAV (and MP3’s) remove files through dropbox or wetransfer.com. We also ask for
                instrumentals versions (no vocals).</p>
              <h6 class="color-secondary-1">Can I work with SoStereo if I have a deal with a label or a publisher?</h6>
              <p>
                Absolutely, we work with many labels and publishers. Our goal is to represent indie artists and act as
                an aggregate to their Label/Pub affiliates
                to help push their music for sync purposes and as a way to facilitate brands discovering artists for
                brand partnerships.
              </p>
              <h6 class="color-secondary-1">Do I need to be a member of a Performance Rights Organization (PRO) to join
                SoStereo?</h6>
              <p>
                No, you don’t need to be a member of a PRO. Once you license a song and it gets publicly performed, you
                are entitled to receiving public
                performance royalties. We recommend you become a member of your national
                PRO as soon as possible so you can take advantage and get 100% of the
                public performance royalties.
              </p>
              <h6 class="color-secondary-1">I wrote my music with other songwriters. Do I have to let them know about
                SoStereo?</h6>
              <p>
                Yes, if there are other writers that have collaborated on your songs they have to sign the agreement as
                well. Our agreements needs to
                be signed by all owners of Master(sound recording) and Publishing(composition).
                If they have any questions about how we work with artists feel free to direct
                them to the FAQ section or to contact us directly at hello&#64;sostereomusic.com
              </p>
              <h6 class="color-secondary-1">Is there an agreement I have to sign?</h6>
              <p>
                Yes. After you request an account our A&R team will reach out sending you all the paperwork and next
                steps.</p>
              <h6 class="color-secondary-1">What happens after I sign the agreement and send you the audio files?</h6>
              <p>
                After you sign the agreement and send us the files your music goes through our own ingestion process. We
                first upload all the music with
                the right metadata and artist information. Then we assign it to
                different music analysts that can rate your music with more than
                1000+ characteristics in the fields of: Harmony, Rhythm, Lyrics,
                BPM, Vocals, Genres, Moods, Themes and many others. Once the music is
                rated we’ll publish it and send you an email with credentials to your account.
              </p>
              <h6 class="color-secondary-1">What can I do with my artist account?</h6>
              <p>
                With your artist account you can edit info including location, bio, profile and background images
                pictures, tags and other relevant info you want
                to add to your profile. We also have the capability to connect your
                profile with Facebook and other social media platforms. To do this,
                just click the connect to Facebook button on the right hand side,
                then accept basic terms and permissions to allow us to fetch your
                public data into your SoStereo account.
              </p>
              <h6 class="color-secondary-1">Can I share my artist page link on my social media channels?</h6>
              <p>
                Of course, our site is completely shareable with all descriptions and images embedded in every post on
                FB, Twitter</p>
              <h6 class="color-secondary-1">Can I approve or deny a given license request?</h6>
              <p>
                No. One of our main values to our clients is a hassle free experience and a quick turnaround, we promise
                a quick clearance. It would be a
                disservice to our clients if we’d look at each use individually to confirm
                specifics. Nonetheless, we do provide a feature called “Artist Restrictions”
                which allows you pick where you want the music to be pitched and placed.
                You can select a list of specific brands or certaing industries
                you do not want be associated with including Politics,
                Religion, Tobacco, Alcohol, Fast Food etc. By creating these artist
                restrictions your music will only be pitched to the right clients
                and industries based on your preference.
              </p>
              <h6 class="color-secondary-1">Do I get paid royalties?</h6>
              <p>Yes, but not through us. You’ll receive those from your Performance Rights Organization. </p>
              <h6 class="color-secondary-1">When do I get paid?</h6>
              <p>SoStereo pays out no later than 30 days after receiving the funds for placing your song.</p>
              <h6 class="color-secondary-1">Can I upload a cover song?</h6>
              <p>No, we only accept original works.</p>
              <h6 class="color-secondary-1">How do I improve my chances of success within SoStereo?</h6>
              <ul>
                <li>Make amazing music.</li>
                <li>Complete your profile with a picture & bio</li>
                <li>Connect your facebook accounts and other social media pages</li>
                <li>Make sure you deliver instrumental versions of your songs</li>
                <li>Make sure you keep us updated on any news</li>
              </ul>
              <h6 class="color-secondary-1">How important is to send you instrumental versions of my music?</h6>
              <p>
                Sometimes a client is only interested in licensing an instrumental song so it’s good to have them
                available, otherwise they won’t
                consider them for those specific opportunities.
              </p>
              <h6 class="color-secondary-1">Am I notified when there is a placement?</h6>
              <p>
                Yes. We’ll let you know if there’s interest in a song, from then on we’ll fill you in on the status of
                the project. </p>
              <h6 class="color-secondary-1">Have some more questions?</h6>
              <p>
                No worries, contact the A&R team at hello&#64;sostereomusic.com. We’ll get back to you within 24hrs.</p>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</section>
