<header class="header-bar" id="spaces-header">
  <nav class="navbar-default">
    <div *ngIf="!loggedUser" class="navbar-header pull-right">
      <ng-container *ngIf="subdomain">
        <button id="headerLoginBtn" type="button" (click)="login()"
                class="mobile-hidden btn primary-dark-btn lg-btn navbar-btn white">Log in
        </button>
        <button id="signUpBtn" type="button" (click)="signUp()"
                class="mobile-hidden btn primary-light-btn lg-btn navbar-btn">Sign up
        </button>
      </ng-container>
      <ng-container *ngIf="!subdomain">
        <button id="requestDemoBtn" type="button" (click)="contactUsModalComponent.showModal()"
                class="mobile-hidden btn primary-light-btn lg-btn navbar-btn">Get in touch
        </button>
      </ng-container>
    </div>
  </nav>
</header>
<app-contact-us-modal #contactUsModalComponent></app-contact-us-modal>
