<footer id="footer-container">
  <div *ngFor="let option of footerOptions">
    <a
      [routerLink]="option.route"
      (click)="goTo(option)"
      class="footer-item"
      [ngClass]="{
        'active-option':
          currentRoute === option.route ||
          (option.name === 'Search' && currentRoute.includes('music-sync-licensing/search')) ||
          (option.name === 'Playlists' && currentRoute.startsWith('/my-playlists'))
      }"
    >
      <span
        aria-hidden="false"
        aria-label=""
        class="material-icons footer-google-icon"
        [attr.data-icon]="option.icon"
        [ngStyle]="{
          'margin-top': company?.theme?.sidebarMenu[0] && option.icon === 'home' ? '-5px' : '6px'
        }"
      >
      </span>
      <div
        class="footer-text-container"
        [ngStyle]="{
          'margin-top': company?.theme?.sidebarMenu[0] && option.icon === 'home' ? '1px' : '5px'
        }"
      >
        <h6 class="footer-text" style="white-space: break-spaces">{{ option.name }}</h6>
      </div>
    </a>
    <span
      *ngIf="option.name === 'Playlists' && playlistsCount > 0"
      class="mobile-pl-count"
      [ngStyle]="{ left: company && !company?.theme?.sidebarMenu[0] && '47vw' }"
      [@inOutAnimation]
    >
      {{ playlistsCount }}
    </span>
    <span *ngIf="option.name === 'Profile'" class="mobile-profile-percentage" [@inOutAnimation]>
      {{ completeProfilePerc }}
    </span>
  </div>
</footer>
<app-brief-submission-modal #briefSubmissionModalComponent></app-brief-submission-modal>
