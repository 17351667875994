<header class="header-bar" id="main-header" [ngClass]="{ 'header-index': isSonicAnalysisUrl }">
  <nav
    class="navbar-default"
    *ngIf="showMainHeader()"
    [hidden]="
      playerService.isSmallSize &&
      !scrolled &&
      (currentUrl.includes('/search') ||
        currentUrl.includes('/discover') ||
        currentUrl.includes('/my-playlist'))
    "
  >
    <div class="mobile-menu">
      <span class="material-icons-outlined" (click)="activeMobileMenu = true"> menu </span>
      <div class="card-background-items" [ngClass]="{ active: activeMobileMenu }">
        <div class="menu-items">
          <div class="d-flex justify-content-end">
            <span class="material-icons-outlined" (click)="activeMobileMenu = false"> menu </span>
          </div>
          <ng-container *ngTemplateOutlet="buttonsTemplate"> </ng-container>
        </div>
      </div>
    </div>
    <div class="navbar-header pull-right mobile-hidden">
      <ng-container *ngTemplateOutlet="buttonsTemplate"> </ng-container>
    </div>
  </nav>
</header>

<ng-template #buttonsTemplate>
  <ng-container *ngIf="!company?.theme?.hideHeader">
    <!-- WrapUp button -->
    <!-- <button
    id="headerWrapUp"
    type="button"
    [routerLink]="'/wrapup'"
    [ngClass]="{ 'selected-btn': currentUrl.includes('/wrapup') }"
    class=" btn white-btn lg-btn navbar-btn "
    (click)="
      trackEvent('Header Section Clicked', 'click', {
        event_action: 'Header section clicked',
        event_type: 'Header Click',
        event_value: '2023 Wrap Up'
      })
    "
  >
    2023 Wrap Up
  </button> -->
    <a
      id="headerOurWork"
      type="button"
      [routerLink]="'/'"
      [ngClass]="{
        'selected-btn': currentUrl === '/',
        'show-for-mobile': loggedUser || !showHomeButton()
      }"
      class="btn white-btn lg-btn navbar-btn home-btn"
      (click)="
        trackEvent('Header Section Clicked', 'click', {
          event_action: 'Header section clicked',
          event_type: 'Header Click',
          event_value: 'Home'
        })
      "
    >
      Home
    </a>
    <a
      id="headerServices"
      type="button"
      [routerLink]="'/services'"
      [ngClass]="{ 'selected-btn': currentUrl === '/services' }"
      class="btn white-btn lg-btn navbar-btn"
      (click)="
        trackEvent('Header Section Clicked', 'click', {
          event_action: 'Header section clicked',
          event_type: 'Header Click',
          event_value: 'Services'
        })
      "
    >
      SERVICES
    </a>
    <a
      id="headerOurWork"
      type="button"
      [routerLink]="'/our-work'"
      [ngClass]="{ 'selected-btn': currentUrl.includes('/our-work') }"
      class="btn white-btn lg-btn navbar-btn"
      (click)="
        trackEvent('Header Section Clicked', 'click', {
          event_action: 'Header section clicked',
          event_type: 'Header Click',
          event_value: 'Our Work'
        })
      "
    >
      Our Work
    </a>
    <a
      id="headerWhoWeAre"
      type="button"
      [routerLink]="'/who-we-are'"
      class="btn white-btn lg-btn navbar-btn"
      [ngClass]="{ 'selected-btn': currentUrl === '/who-we-are' }"
      (click)="
        trackEvent('Header Section Clicked', 'click', {
          event_action: 'Header section clicked',
          event_type: 'Header Click',
          event_value: 'Who We Are'
        })
      "
    >
      Who We Are
    </a>
    <a
      id="headerDiscover"
      type="button"
      [routerLink]="'/discover'"
      class="btn white-btn lg-btn navbar-btn"
      [ngClass]="{ 'selected-btn': currentUrl === '/discover' }"
      (click)="
        trackEvent('Header Section Clicked', 'click', {
          event_action: 'Header section clicked',
          event_type: 'Header Click',
          event_value: 'Discover'
        })
      "
    >
      Discover
    </a>
    <a
      id="headerNews"
      type="button"
      (click)="goToBlog()"
      [href]="this.environment.appUrl + '/blog/'"
      target="_blank"
      class="btn white-btn lg-btn navbar-btn"
    >
      News
    </a>
    <a
      id="headerLogin"
      (click)="
        trackEvent('Header Section Clicked', 'click', {
          event_action: 'Header section clicked',
          event_type: 'Header Click',
          event_value: 'Log in'
        })
      "
      [routerLink]="'/login?returnUrl=' + router.url"
      *ngIf="!loggedUser"
      class="btn white-btn lg-btn navbar-btn"
    >
      client access
    </a>
  </ng-container>
  <ng-container *ngIf="company?.theme?.hideHeader">
    <a
      id="headerLogin"
      (click)="
        trackEvent('Header Section Clicked', 'click', {
          event_action: 'Header section clicked',
          event_type: 'Header Click',
          event_value: 'Log in'
        })
      "
      [routerLink]="'/login?returnUrl=' + router.url"
      *ngIf="!loggedUser"
      class="btn white-btn lg-btn navbar-btn"
    >
      client access
    </a>
  </ng-container>
</ng-template>
