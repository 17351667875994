import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  isSessionExpired = new BehaviorSubject<boolean>(false);

  sessionExpired() {
    this.isSessionExpired.next(true);
  }
}
