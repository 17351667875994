<div class="commentContent" [style.left.px]="offsetLeft" *ngIf="showNewSegmentPopUp">
  <i class="fa fa-times closeIcon" (click)="hidePopUpAndSegmentArea()"></i>
  <div class="thumbnailSimilar primary-dark">
    <h3>AI similar search</h3>
    <div class="row ml0 mr0">
      <p class="subtitle">Select the segment of the track you want to use as reference</p>
      <h4 class="now-playing pb10 pt10">{{segment.startTime | wavesurferTimeFormat}}
        - {{segment.endTime | wavesurferTimeFormat}}</h4>
    </div>
    <div class="caption" style="padding-top: 10px;">
      <div *ngIf="loggedUser || router.url.indexOf('spaces/submission') !== -1">
        <span class="d-flex align-items-center justify-content-space-between" style="width:80%;">
          <button class="btn outline-primary-btn mr10" (click)="searchForSegment(segment)">
            Search
          </button>
          OR
          <a (click)="searchFullTrack()">
            Use full track
          </a>
        </span>
      </div>
    </div>
    <div class="row ml0 mr0 mt20">
      <p>Tip: You can also add any filter tags ex: positive,female vocals</p>
    </div>
  </div>
</div>
<div class="newSegmmentSection" (mousemove)="toggleSegmentIcon($event)" (mouseleave)="toggleSegmentIcon()"
  (click)="addSegment($event)" *ngIf="!showNewSegmentPopUp">
  <div class="segmentIconContent">
    <i class="fa fa-bookmark segmentIcon"></i>
  </div>
</div>
<!-- <div class="segmentSection" (mouseup)="updateSegmentSection()"></div> -->