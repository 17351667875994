export const footerOptions = [
  {
    name: 'Home',
    icon: 'home',
    route: '/',
  },
  {
    name: 'Search',
    icon: 'search',
    route: '/search',
  },
  {
    name: 'Discover',
    icon: 'explore',
    route: '/discover',
  },
  {
    name: 'Playlists',
    icon: 'playlist_play',
    route: '/my-playlists',
  },
  {
    name: 'Our Work',
    icon: 'local_fire_department',
    route: '/our-work',
  },
];

export const artistsFooterOptions = [
  {
    name: 'Dashboard',
    icon: 'grid_view',
    route: '/artists/dashboard',
  },
  {
    name: 'Search',
    icon: 'search',
    route: '/search',
  },
  {
    name: 'Discover',
    icon: 'explore',
    route: '/discover',
  },
  {
    name: 'Playlists',
    icon: 'playlist_play',
    route: '/my-playlists',
  },
  {
    name: 'Profile',
    icon: 'portrait',
    route: '/artists/account-setup/me',
  },
];

export const brandsFooterOptions = [
  {
    name: 'About Us',
    icon: 'question_mark',
    route: '/landing',
  },
  {
    name: 'Search',
    icon: 'search',
    route: '/search',
  },
  {
    name: 'Discover',
    icon: 'explore',
    route: '/discover',
  },
  {
    name: 'Playlists',
    icon: 'playlist_play',
    route: '/my-playlists',
  },
  {
    name: 'Send Brief',
    icon: 'quickreply',
  },
];

export const brandsFooterOptionsNoHome = [
  {
    name: 'Search',
    icon: 'search',
    route: '/search',
  },
  {
    name: 'Discover',
    icon: 'explore',
    route: '/discover',
  },
  {
    name: 'Playlists',
    icon: 'playlist_play',
    route: '/my-playlists',
  },
  {
    name: 'About Us',
    icon: 'question_mark',
    route: '/landing',
  },
  {
    name: 'Send Brief',
    icon: 'quickreply',
  },
];
