<!-- <div *ngIf="!showPage" id="init-sostereo-app">
  <figure>
    <span>Initializing...</span>
  </figure>
</div> -->
<ng-container *ngIf="showLayout && showPage">
  @if (showSearchHeader) {
  <app-session-buttons></app-session-buttons>
  } @else {
  <div ui-view="header" *ngIf="!layoutOptions?.spacesHeader">
    @if (isBrowser) {
    <app-main-header></app-main-header>
    } @if (isBrowser && !cookieConsent) {
    <app-cookie-consent (cookieConsent)="setCookieConsent()"> </app-cookie-consent>
    }
  </div>
  <div *ngIf="layoutOptions?.spacesHeader && !this.currentUrl.includes('/spaces/submission/')">
    <app-spaces-header> </app-spaces-header>
  </div>
  } @if ((loggedUser || showSidenav()) && !isPitch()) {
  <div class="sidenav-spacer"></div>
  <app-sidenav [scopes]="scopes"></app-sidenav>
  } @if (playerService.showSearchSidebar) {
  <app-search-sidebar></app-search-sidebar>
  }
</ng-container>
@if (showPage) {
<main class="page-content">
  @if (technicalIssues) {
  <app-technical-issues></app-technical-issues>
  }
  <router-outlet></router-outlet>
</main>
}
<app-user-sign-up-modal #userSignUpModalComponent></app-user-sign-up-modal>
<app-session-expired-modal #sessionExpiredModal></app-session-expired-modal>
@defer (when currentUrl !== '/') {
<app-player-widget
  [hasBottomMenu]="this.isMobileOrTablet && (loggedUser || showSidenav())"
></app-player-widget>
} @if (this.isMobileOrTablet && (loggedUser || showSidenav()) && !isPitch()) {
<app-mobile-footer></app-mobile-footer>
}
