import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandlingService } from '../angular/shared/services/error-handling.service';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { startsWith } from 'lodash-es';
import { AuthenticationService } from 'sostereo-services';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  isServer: boolean;
  constructor(
    private errorHandlingService: ErrorHandlingService,
    private router: Router,
    private authenticationService: AuthenticationService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.isServer = isPlatformServer(platformId);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('satellizer_token');
    const split = req.url.split('/');
    if (
      token &&
      !req.url.includes('s3.') &&
      !req.url.includes('s3-') &&
      !(split[split.length - 1] === 'sliders' && req.method === 'GET')
    ) {
      req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
    }

    if (token && req.url.includes('stereospaces/v') && req.url.endsWith('/zip')) {
      req = req.clone({ headers: req.headers.append('ngsw-bypass', 'true') });
    }

    if (
      req.url.includes('tags-rs') ||
      req.url.includes('/tags-categories') ||
      req.url.includes('metrics/v') ||
      (req.url.includes('stereospaces/v') &&
        split[split.length - 1] !== 'identity' &&
        req.url.includes('companies') &&
        !req.urlWithParams.includes('/companies?') &&
        req.method === 'GET') ||
      (req.url.includes('organization/v') &&
        split[split.length - 1] !== 'identity' &&
        req.url.includes('companies') &&
        !req.urlWithParams.includes('/companies?') &&
        req.method === 'GET' &&
        !req.urlWithParams.includes('signedUrl')) ||
      (split[split.length - 1] === 'sliders' && req.method === 'GET') ||
      (this.router.url.includes('/sliders/edit/') &&
        req.url.includes('playlists') &&
        split[split.length - 1] !== 'playlists' &&
        req.method === 'GET') ||
      (!req.url.includes('api') &&
        !req.url.includes('stereospaces/v') &&
        !req.url.includes('notifications') &&
        !req.url.includes('storage') &&
        !req.url.includes('iam') &&
        !req.url.includes('discover') &&
        !req.url.includes('core'))
    ) {
      req = req.clone({ withCredentials: false, headers: req.headers.delete('Authorization') });
      if (req.url.includes('partials')) {
        // TODO: cacheBuster for ng7
        let cacheBuster = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
          cacheBuster += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        req = req.clone({ url: req.url + '?v=' + cacheBuster });
      }
    }
    if (
      !req.headers.has('Content-Type') &&
      !req.url.includes('s3.') &&
      !req.url.includes('image') &&
      !req.url.includes('tags-rs')
    ) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    if (this.isServer) {
      req = req.clone({ headers: req.headers.set('Origin', 'http://0.0.0.0:4200') });
    }
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error.status);
        if (error.status === 401 && this.authenticationService.getCurrentUser()) {
          this.errorHandlingService.sessionExpired();
        }
        if (error.status === 403 || startsWith(error?.status?.toString(), '5')) {
          Sentry.captureException(
            new Error(
              `API error: status ${error.status} - ${error?.error?.message || error.message}`,
            ),
          );
        }
        return throwError(() => error);
      }),
    );
  }
}
