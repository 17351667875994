import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { RedirectionComponent } from './components/redirection/redirection.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FaqComponent } from './components/faq/faq.component';
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { environment } from '../environments/environment';
import { LoadingComponent } from './components/loading/loading.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('./angular/modules/landing/landing.module').then((m) => m.LandingModule),
    title: 'SoStereo: Real music by real artists, real fast and easy',
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('./angular/modules/landing/landing.module').then((m) => m.LandingModule),
    title: 'SoStereo: Real music by real artists, real fast and easy',
  },
  {
    path: 'explore',
    loadChildren: () =>
      import('./angular/modules/explore/explore.module').then((m) => m.ExploreModule),
    title: 'Explore',
  },
  {
    path: 'stereobrands',
    loadChildren: () =>
      import('./angular/modules/brands/brands.module').then((m) => m.BrandsModule),
    title: 'StereoBrands',
  },
  {
    path: 'who-we-are',
    loadChildren: () =>
      import('./angular/modules/about-us/about-us.module').then((m) => m.AboutUsModule),
    title: 'Who We Are',
  },
  {
    path: 'services',
    loadChildren: () =>
      import('./angular/modules/our-services/our-services.module').then((m) => m.OurServicesModule),
    title: 'Services',
  },
  {
    path: 'linkedin-submit-brief',
    loadChildren: () =>
      import('./angular/modules/brief-form/brief-form.module').then((m) => m.BriefFormModule),
  },
  {
    path: 'lbb-submit-brief',
    loadChildren: () =>
      import('./angular/modules/brief-form/brief-form.module').then((m) => m.BriefFormModule),
  },
  {
    path: 'our-story',
    loadChildren: () =>
      import('./angular/modules/our-story/our-story.module').then((m) => m.OurStoryModule),
    title: 'Our Story',
  },
  {
    path: 'session-expired',
    loadChildren: () =>
      import('./angular/modules/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'submit-brief',
    loadChildren: () =>
      import('./angular/modules/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'submit-music',
    loadChildren: () =>
      import('./angular/modules/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'tiers',
    loadChildren: () => import('./angular/modules/tiers/tiers.module').then((m) => m.TiersModule),
    canActivate: [AuthGuard],
    title: 'Tiers',
  },
  {
    path: 'sliders',
    loadChildren: () =>
      import('./angular/modules/sliders/sliders.module').then((m) => m.SlidersModule),
    canActivate: [AuthGuard],
    title: 'Sliders',
  },
  {
    path: 'spaces',
    loadChildren: () =>
      import('./angular/modules/projects/projects.module').then((m) => m.ProjectsModule),
    title: 'Projects',
  },
  {
    path: 'ingestions',
    loadChildren: () =>
      import('./angular/modules/ingestions/ingestions.module').then((m) => m.IngestionsModule),
    canActivate: [AuthGuard],
    title: 'Ingestions',
  },
  {
    path: 'blog',
    component: AuthGuard,
    data: {
      externalUrl: `${environment.appUrl}/blog/`,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'library',
    loadChildren: () =>
      import('./angular/modules/library/library.module').then((m) => m.LibraryModule),
    canActivate: [AuthGuard],
    title: 'Library',
  },
  {
    path: 'kitchen-sink',
    loadChildren: () =>
      import('./angular/modules/kitchen-sink/kitchen-sink.module').then((m) => m.KitchenSinkModule),
    title: 'Kitchen Sink',
  },
  {
    path: 'discover',
    loadChildren: () =>
      import('./angular/modules/discover/discover.module').then((m) => m.DiscoverModule),
    title: 'Discover',
  },
  {
    path: 'artists',
    loadChildren: () =>
      import('./angular/modules/artists/artists.module').then((m) => m.ArtistsModule),
    canActivate: [AuthGuard],
    title: 'Artists',
  },
  {
    path: 'songs',
    loadChildren: () => import('./angular/modules/songs/songs.module').then((m) => m.SongsModule),
    canActivate: [AuthGuard],
    title: 'Songs',
  },
  {
    path: 'ads',
    loadChildren: () => import('./angular/modules/ads/ads.module').then((m) => m.AdsModule),
    canActivate: [AuthGuard],
    title: 'Ads',
  },
  {
    path: 'albums',
    loadChildren: () =>
      import('./angular/modules/albums/albums.module').then((m) => m.AlbumsModule),
    canActivate: [AuthGuard],
    title: 'Albums',
  },
  {
    path: 'my-playlists',
    loadChildren: () =>
      import('./angular/modules/playlists/playlists.module').then((m) => m.PlaylistsModule),
    title: 'Playlists',
  },
  {
    path: 'artists-list',
    loadChildren: () =>
      import('./angular/modules/artists-view/artists-view.module').then((m) => m.ArtistsViewModule),
    title: 'Artists List',
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./angular/modules/discover/discover.module').then((m) => m.DiscoverModule),
    title: 'Discover',
  },
  {
    path: 'company-settings',
    loadChildren: () =>
      import('./angular/modules/company-settings/company-settings.module').then(
        (m) => m.CompanySettingsModule,
      ),
    canActivate: [AuthGuard],
    data: { scopes: ['CreateIdentitie'] },
    title: 'Company Set Up',
  },
  {
    path: 'account-settings',
    loadChildren: () =>
      import('./angular/modules/account-settings/account-settings.module').then(
        (m) => m.AccountSettingsModule,
      ),
    canActivate: [AuthGuard],
    title: 'My Account',
  },
  {
    path: 'user-sign-up/:token',
    loadChildren: () =>
      import('./angular/modules/discover/discover.module').then((m) => m.DiscoverModule),
  },
  {
    path: 'reset-password/:resetPasswordToken',
    loadChildren: () =>
      import('./angular/modules/discover/discover.module').then((m) => m.DiscoverModule),
  },
  {
    path: 'invite-sign-up/creator',
    loadChildren: () =>
      import('./angular/modules/discover/discover.module').then((m) => m.DiscoverModule),
  },
  {
    path: 'music-sync-licensing',
    loadChildren: () =>
      import('./angular/modules/player/player.module').then((m) => m.PlayerNgModule),
    title: 'Music Licensing Search',
  },
  {
    path: 'rating',
    loadChildren: () =>
      import('./angular/modules/rating/rating.module').then((m) => m.RatingModule),
    canActivate: [AuthGuard],
    title: 'Rating',
  },
  {
    path: 'agreements',
    loadChildren: () =>
      import('./angular/modules/agreements/agreements.module').then((m) => m.AgreementsModule),
    canActivate: [AuthGuard],
    title: 'Agreements',
  },
  {
    path: 'policies',
    loadChildren: () =>
      import('./angular/modules/policies/policies.module').then((m) => m.PoliciesModule),
    canActivate: [AuthGuard],
    title: 'Policies',
  },
  {
    path: 'tags',
    loadChildren: () => import('./angular/modules/tags/tags.module').then((m) => m.TagsModule),
    canActivate: [AuthGuard],
    title: 'Tags',
  },
  {
    path: 'companies',
    loadChildren: () =>
      import('./angular/modules/companies/companies.module').then((m) => m.CompaniesModule),
    canActivate: [AuthGuard],
    title: 'Companies',
  },
  {
    path: 'iam',
    loadChildren: () => import('./angular/modules/iam/iam.module').then((m) => m.IamModule),
    canActivate: [AuthGuard],
    title: 'People & Permission',
  },
  {
    path: 'analytics',
    loadChildren: () =>
      import('./angular/modules/analytics/analytics.module').then((m) => m.AnalyticsModule),
    canActivate: [AuthGuard],
    data: { scopes: ['GetPlaylistAnalytics', '*', 'UpdateSongRateStatus'] },
    title: 'Analytics',
  },
  {
    path: 'transactions',
    loadChildren: () =>
      import('./angular/modules/transactions/transactions.module').then(
        (m) => m.TransactionsModule,
      ),
    canActivate: [AuthGuard],
    title: 'Transactions',
  },
  {
    path: 'pitch',
    loadChildren: () =>
      import('./angular/modules/presentations/presentations.module').then(
        (m) => m.PresentationsModule,
      ),
    title: 'Presents',
  },
  {
    path: 'restrictions',
    loadChildren: () =>
      import('./angular/modules/restrictions/restrictions.module').then(
        (m) => m.RestrictionsModule,
      ),
    canActivate: [AuthGuard],
    title: 'Restrictions',
  },
  {
    path: 'wrapup',
    loadChildren: () =>
      import('./angular/modules/year-wrap/year-wrap.module').then((m) => m.YearWrapModule),
  },
  {
    path: '2020-insights',
    loadChildren: () =>
      import('./angular/modules/year-insights/year-insights.module').then(
        (m) => m.YearInsightsModule,
      ),
  },
  {
    path: '2020-story',
    loadChildren: () =>
      import('./angular/modules/year-insights/year-insights.module').then(
        (m) => m.YearInsightsModule,
      ),
  },
  {
    path: 'sonic-analysis',
    loadChildren: () =>
      import('./angular/modules/creative-analysis/creative-analysis.module').then(
        (m) => m.CreativeAnalysisModule,
      ),
    canActivate: [AuthGuard],
    title: 'Sonic Analysis',
  },
  {
    path: 'wine',
    loadChildren: () =>
      import('./angular/modules/wine-section/wine-section.module').then((m) => m.WineSectionModule),
    title: 'Sostereo Wine Club',
  },
  {
    path: 'our-work',
    loadChildren: () =>
      import('./angular/modules/our-work-section/our-work-section.module').then(
        (m) => m.OurWorkSectionModule,
      ),
  },
  {
    path: 'transfer',
    loadChildren: () =>
      import('./angular/modules/transfer/transfer.module').then((m) => m.TransferModule),
    title: 'Transfers',
  },
  {
    path: 'opportunities',
    loadChildren: () =>
      import('./angular/modules/opportunities/opportunities.module').then(
        (m) => m.OpportunitiesModule,
      ),
    canActivate: [AuthGuard],
    data: { scopes: ['ListInquiries'] },
    title: 'Opportunities',
  },
  {
    path: 'references',
    loadChildren: () =>
      import('./angular/modules/references/references.module').then((m) => m.ReferencesModule),
    title: 'References',
  },
  {
    path: 'login/',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'loading/',
    component: LoadingComponent,
    title: 'SoStereo: Real music by real artists, real fast and easy',
  },
  {
    path: 'signup/',
    redirectTo: 'signup',
    pathMatch: 'full',
  },
  {
    path: 'who-we-are/',
    loadChildren: () =>
      import('./angular/modules/about-us/about-us.module').then((m) => m.AboutUsModule),
    title: 'Who We Are',
  },
  {
    path: 'our-work/',
    loadChildren: () =>
      import('./angular/modules/our-work-section/our-work-section.module').then(
        (m) => m.OurWorkSectionModule,
      ),
  },
  {
    path: 'discover/',
    loadChildren: () =>
      import('./angular/modules/discover/discover.module').then((m) => m.DiscoverModule),
    title: 'Discover',
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./components/tenant-login/login-registration.component').then(
        (c) => c.LoginRegistrationComponent,
      ),
    title: 'Sostereo: Login',
  },
  {
    path: 'signup',
    loadComponent: () =>
      import('./components/tenant-login/login-registration.component').then(
        (c) => c.LoginRegistrationComponent,
      ),
    title: 'Sostereo: Signup',
  },
  {
    path: 'invite/:id',
    loadComponent: () =>
      import('./components/tenant-login/login-registration.component').then(
        (c) => c.LoginRegistrationComponent,
      ),
  },
  {
    path: 'faq',
    component: FaqComponent,
    title: 'Sostereo: FAQ',
  },
  {
    path: 'newsletter',
    component: NewsletterComponent,
    title: 'Sostereo: Newsletter',
  },
  {
    path: 'terms-of-service',
    component: TermsOfServiceComponent,
    title: 'Sostereo: Terms of Service',
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    title: 'Sostereo: Privacy Policy',
  },
  {
    path: 'cookies-policy',
    component: CookiesPolicyComponent,
    title: 'Sostereo: Cookies Policy',
  },
  {
    path: 'auth/:authToken',
    component: RedirectionComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    title: 'Sostereo: Not-Found',
  },
  {
    path: 'loading',
    component: LoadingComponent,
    title: 'SoStereo: Real music by real artists, real fast and easy',
  },
  {
    path: '**',
    component: RedirectionComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: NoPreloading,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
