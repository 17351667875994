import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { CommonService } from './common.service';
import { SeoService } from './seo.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  isBrowser: boolean;
  constructor(
    private commonService: CommonService,
    private seoService: SeoService,
    @Inject('environment') private environment,
    @Inject(PLATFORM_ID) platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  setTheme(theme) {
    const bodyContainer = document.getElementsByTagName('body')[0];
    for (const key in theme) {
      if (this.isBrowser) {
        bodyContainer.style.setProperty(theme[key]?.name, theme[key]?.value);
      }
      // This is to change waveColor from player widget
      if (key === 'secondary') {
        this.commonService.waveColor.next(theme[key].value);
      }
      if (key === 'playerBg') {
        this.commonService.playerBg.next(theme[key].value);
      }
      if (key === 'discoverBg') {
        this.commonService.playerBg.next(theme[key].value);
      }
      if (key === 'pageDescription') {
        this.commonService.pageDescription.next(theme[key]);
      }
      if (key === 'pageTitle') {
        this.commonService.pageTitle.next(theme[key]);
      }
    }
    this.updateSeo(theme);
  }

  updateSeo(data) {
    const seoData = {
      imageWidth: '1600',
      imageHeight: '800',
      pageBackground:
        data.seoBg ||
        `https://img-sostereo.sostereo.com/sostereo-images-${
          this.environment.production ? 'production' : 'stg'
        }/logos/${this.commonService.tenantId}.png` ||
        this.environment.seoBg,
      pageTitle: data.pageTitle || this.environment.title,
      pageDescription: data.pageDescription || this.environment.description,
    };
    this.seoService.updateImageSeo(seoData);
    this.seoService.updateBasicSeo(seoData);
  }
}
