import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { clone } from 'lodash-es';
import { WavesurferTimeFormatPipe } from '../pipes/wavesurfer-time-format.pipe';
import { SearchTagsService } from './search-tags.service';

@Injectable({
  providedIn: 'root',
})
export class SimilarSongService {
  constructor(
    private searchTagsService: SearchTagsService,
    private router: Router,
    private wavesurferTimeFormatPipe: WavesurferTimeFormatPipe,
  ) {}

  public setStorageSimilarSong(track) {
    const songData = {
      versions: {
        instrumental: {},
        vocals: {},
        match: track.objectData,
        others: [],
      },
    };
    songData.versions.match.trackId = track.trackId;
    songData.versions.vocals = songData.versions.match;
    localStorage.setItem('SOSTEREO.similarSongRef', JSON.stringify(songData));
    return songData;
  }

  private createSegment(track: any, segment: any) {
    let params = this.getSearchParams();
    const refParams: any = {
      explain: true,
      fields: 'segmentsGroups',
      seed_groups: segment.group,
    };
    refParams[track.objectData.seed_youtube ? 'seed_youtube' : 'seed_songIds'] = track.trackId;

    if (track.version?.toLowerCase() == 'instrumental') {
      refParams.vocals = false;
    } else {
      delete params?.vocals;
    }
    params = { ...params, ...refParams };

    segment.showSegmentDetails = false;
    this.router.navigate(['/music-sync-licensing/search'], { queryParams: params });
  }

  public goToYtSegment(track: any, segment: any) {
    this.createSegment(track, segment);
    const songData = {
      versions: {
        instrumental: {},
        vocals: {},
        match: {
          artistName: '',
          name: `Ext. Reference: ${track.name}`,
          fileUrls: track.url,
          trackId: track.trackId,
        },
        others: [],
      },
    };
    const refTag = {
      category: 'youtube-ref',
      label: `${track.name} ${this.wavesurferTimeFormatPipe.transform(
        segment.start,
      )}-${this.wavesurferTimeFormatPipe.transform(segment.endTime)}`,
      slug: track.trackId,
      type: 'refSong',
      verifying: false,
      songData: songData,
    };
    this.searchTagsService.refSongTag(refTag);
  }

  public goToRefSegment(track: any, segment: any) {
    this.createSegment(track, segment);
    const refTag = {
      category: track.objectData.seed_youtube ? 'youtube-ref' : 'ref-song',
      label: `Ref: ${track.name} ${this.wavesurferTimeFormatPipe.transform(
        segment.start,
      )}-${this.wavesurferTimeFormatPipe.transform(segment.endTime)}`,
      slug: track.trackId,
      type: 'refSong',
    };
    this.searchTagsService.refSongTag(refTag);
  }

  private getSearchParams(): Params {
    let params = clone(this.router.routerState.snapshot.root.queryParams);
    delete params?.seed_songIds;
    delete params?.seed_start;
    delete params?.seed_end;
    delete params?.seed_groups;
    delete params?.seed_youtube;
    delete params?.songId;
    delete params?.artists;
    delete params?.versionId;
    params.page = 1;
    params.song = 0;
    return params;
  }

  public goToYtNewSegment(track: any, segment: any) {
    this.createNewSegment(track, segment);
    const songData = {
      versions: {
        instrumental: {},
        vocals: {},
        match: track.objectData,
        others: [],
      },
    };
    const refTag = {
      category: 'youtube-ref',
      label: `${track.name} ${this.wavesurferTimeFormatPipe.transform(
        segment.start || segment.startTime,
      )}-${this.wavesurferTimeFormatPipe.transform(segment.endTime)}`,
      slug: track.trackId,
      type: 'refSong',
      verifying: false,
      songData: songData,
      seed_start: segment.startTime,
      seed_end: segment.endTime,
    };
    this.searchTagsService.refSongTag(refTag);
  }

  private createNewSegment(track: any, segment: any) {
    let params = this.getSearchParams();
    const refParams: any = {
      explain: true,
      fields: 'segmentsGroups',
      seed_start: segment.startTime,
      seed_end: segment.endTime,
    };
    refParams[track.objectData.seed_youtube ? 'seed_youtube' : 'seed_songIds'] = track.trackId;

    if (track.version == 'instrumental' || !track?.objectData?.vocals) {
      refParams.vocals = false;
    } else {
      delete params?.vocals;
    }
    params = { ...params, ...refParams };
    this.router.navigate(['/music-sync-licensing/search'], { queryParams: params });
  }

  public goToRefNewSegment(track: any, segment: any) {
    this.createNewSegment(track, segment);
    const refTag = {
      category: 'ref-song',
      label: `Ref: ${track.name} ${this.wavesurferTimeFormatPipe.transform(
        segment.startTime,
      )}-${this.wavesurferTimeFormatPipe.transform(segment.endTime)}`,
      slug: track.trackId,
      type: 'refSong',
      seed_start: segment.startTime,
      seed_end: segment.endTime,
    };
    this.searchTagsService.refSongTag(refTag);
  }

  public goToRefFullTrack(track: any, isYoutubeReference: boolean) {
    let params = this.getSearchParams();
    const refParams: any = {
      explain: true,
      fields: 'segmentsGroups',
    };
    refParams[isYoutubeReference ? 'seed_youtube' : 'seed_songIds'] = track.trackId;
    if (track.version == 'instrumental') {
      refParams.vocals = false;
    } else {
      delete params?.vocals;
    }
    params = { ...params, ...refParams };
    this.router.navigate(['/music-sync-licensing/search'], { queryParams: params });
    const refTag = {
      category: 'ref-song',
      label: `Ref: ${track.name}`,
      slug: track.trackId,
      type: 'refSong',
    };
    this.searchTagsService.refSongTag(refTag);
  }
}
