import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Location } from '@angular/common';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function () {};
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    console.log('INIT');
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker
    //     .register('ngsw-worker.js?v=' + Date.now(), {
    //       scope: '.', // <--- THIS BIT IS REQUIRED
    //     })
    //     .then(
    //       function (registration) {
    //         registration.update();
    //         // Registration was successful
    //         console.log('ServiceWorker registration successful with scope: ', registration.scope);
    //       },
    //       function (err) {
    //         // registration failed :(
    //         console.log('ServiceWorker registration failed: ', err);
    //       },
    //     )
    //     .catch((err) => console.error(err));
    // }
  })
  .catch((err) => console.error(err));
// Prevent stripping of trailing slash
Location.stripTrailingSlash = function stripTrailingSlashNew(url: string) {
  return url;
};
