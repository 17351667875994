import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserService, AuthenticationService } from 'sostereo-services';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent implements OnInit {
  landing: boolean;
  @Output() cookieConsent = new EventEmitter();

  constructor(
    private userService: UserService,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.landing = event.url !== '/';
      });
  }

  ngOnInit() {}

  addConsentCookie() {
    const loggedUser = this.authenticationService.getCurrentUser();
    if (loggedUser) {
      loggedUser.cookieConsent = true;
      this.userService.update(loggedUser._id, { cookieConsent: true }).subscribe(
        () => {
          localStorage.setItem('SOSTEREO.cookieConsent', 'true');
          this.cookieConsent.emit();
        },
        (err) => {
          console.log(
            'Err at adding cookieConsent to user. userId: ' + loggedUser._id + '. Err: ',
            err,
          );
        },
      );
    } else {
      localStorage.setItem('SOSTEREO.cookieConsent', 'true');
      this.cookieConsent.emit();
    }
  }
}
