<section id="faq">
  <div class="section-content pt0 sidenav-padding">
    <div class="main-content" style="overflow: auto">
      <div class="pl0 pr0 pb0 container-without-bg mt100">
        <div>
          <div class="row ml0 mr0">
            <div class="col-md-11 col-md-offset-1">
              <img class="logo-sm" src="./assets/images/logo.svg"/>
            </div>
          </div>
          <div class="row ml0 mr0 pb20">
            <div class="col-md-8 col-md-offset-2">
              <h1>Terms of service</h1><br/>
              <p>Welcome to SoStereo! This Terms of Use is a legal agreement (&#39;Agreement&#39;) between and among you
                and SoStereo Music Group LLC (&#39;SoStereo&#39;, “Company”, “we”, or “us”) stating the terms that
                govern your use of the SoStereo website (this website and the services available through it are
                individually and collectively referred to in this Agreement as the &#39;Website&#39;, as the context
                requires). By accessing or using the Website in any manner, you signify and verify that you have read,
                understand, and agree to and are bound by the terms of this Agreement. You accept and abide by these
                terms as presented to you, and SoStereo reserves the right to revise, update, add, or remove portions of
                this Agreement at anytime without additional notice to you. It is your responsibility to check the
                Agreement each time before using the Website, and your continued use of the Website will indicate your
                acceptance of any changes. In addition, you agree to comply with all local, state, federal, and national
                laws, statutes, ordinances, and regulations that apply to your use of the Website or Content (defined
                below).</p>
              <p>NOTE: THESE TERMS CONTAIN A DISPUTE RESOLUTION AND ARBITRATION PROVISION, INCLUDING CLASS ACTION WAIVER
                THAT AFFECTS YOUR RIGHTS UNDER THESE TERMS AND WITH RESPECT TO DISPUTES YOU MAY HAVE WITH SOSTEREO. YOU
                MAY OPT OUT OF THE BINDING INDIVIDUAL ARBITRATION AND CLASS ACTION WAIVER AS PROVIDED BELOW.</p>
              <h6 class="color-secondary-1">1. Age Requirement/Eligibility.</h6>
              <p>To register for use of the Website on your own behalf, you must be at least 18 years of age. If you are
                at least 13 years of age, but under 18 years of age, you must present this Agreement to your parent or
                legal guardian, and he or she must click &#39;AGREE&#39; to enter into this Agreement on your behalf.
                Children under 13 years of age may not register for the Website, nor may parents or legal guardians
                register on their behalf. If you are a parent or legal guardian entering this Agreement for the benefit
                of your child or a child in your legal care, be aware that you are fully responsible for the child&#39;s
                use of the Website, including all financial charges and legal liability that he or she may incur. By
                using the Website, you represent and warrant that: (a) all registration information you submit is
                truthful and accurate, you will not create an account for anyone other than yourself, and you will not
                solicit or use the credentials or accounts of other users; (b) you will maintain the accuracy of such
                information at all times; (c) you are 18 years of age or older or, if you are between the ages of 13 and
                17, you represent that you have your parent’s or legal guardian’s express permission to use the Service,
                that they have read this agreement with you, and have accepted these Terms of Use on your behalf; and
                (d) your use of the Website does not violate any applicable law or regulation and you will only use the
                Website and its services for legal purposes.</p>
              <h6 class="color-secondary-1">2. Content.</h6>
              <p>All &#39;Content&#39;, including but not limited to digital downloads of sound recordings and related
                digital content, songs, musical compositions, mixes and loops, musical interludes or snippets, downloads
                or samples, and all software, artwork, graphics, video, text, interfaces, Trademarks, logos, images,
                photographs, and each and every other element of the Website, including the layout, look and feel,
                organization, and coordination of the Website and such Content on the Website, is the property of or is
                licensed to or otherwise controlled by SoStereo, and is protected by U.S. and international trademark,
                trade dress, copyright, and/or other intellectual property rights or other applicable laws. Without the
                prior written consent of SoStereo, and except as expressly provided in this Agreement and intended by
                the purpose of the Website, no Content may be copied, transmitted, distributed, translated, publicly
                displayed, uploaded, published, recorded, retransmitted, rented, sold, distributed, digitized, marketed,
                reproduced, altered to make new works, performed, or compiled in any way and you agree not to engage or
                participate in any of the foregoing. Through the permitted use of the Website, you are permitted to
                listen to Samples (as defined below), and license rights related to digital downloads of sound
                recordings, musical compositions, and related digital content, including songs, mixes and loops, all of
                which are part of the Website&#39;s Content. You agree not to circumvent, disable or otherwise interfere
                with security features of the Website or features that prevent or restrict use or copying of any Content
                or enforce limitations on use of the Website. Some available portions of the Content is sometimes
                referred to herein as &#39;Products&#39;.</p>
              <h6 class="color-secondary-1">3. Objectionable Material.</h6>
              <p>When using the Website, you understand that you may encounter Content that may be deemed objectionable,
                indecent, or offensive, which content may or may not be identified as having explicit language. All
                Content provided on the Website is provided to you “AS IS”, Company makes no representations or
                warranties with respect thereto, and you agree to use the Website at your own risk and SoStereo shall
                have no liability to you for Content that may be found objectionable, indecent, or offensive.</p>
              <h6 class="color-secondary-1">4. Account Registration.</h6>
              <p>(a) To use certain services of the Website you must register and provide certain information (e.g.
                first name, last name, contact email, password, and/or company) to SoStereo (&#39;Registration Data&#39;)
                for the purposes of creating an account, called my &#39;Account&#39;. You agree to provide current,
                complete, and accurate Registration Data at the time you register, you will update your Registration
                Data as necessary to keep it current, complete and accurate, and you will not create an Account for
                anyone other than yourself. SoStereo may terminate your Account and any or all rights to use the Website
                without notice if any information you provide is inaccurate, false, or incomplete.</p>
              <p>(b) You are solely responsible for maintaining the confidentiality and security of your Account. You
                agree to notify SoStereo immediately of any unauthorized use of your password and/or account. SoStereo
                shall not be responsible for any losses arising out of the unauthorized use of your Registration Data or
                account, and you agree to hold harmless and to indemnify SoStereo, its partners, parents, subsidiaries,
                agents, affiliates and/or licensors, as applicable, for any improper, unauthorized or illegal uses of
                your Account. You may not attempt to gain unauthorized access to the Website. Should you attempt to do
                so, assist others in making such attempts, or distributing instructions, software or tools for that
                purpose, SoStereo will terminate your Account without notice, in addition to any other remedies it may
                have available.</p>
              <h6 class="color-secondary-1">5. Consent to our communication with You by E-Mail.</h6>
              <p>By establishing a SoStereo Account, you grant permission for SoStereo and its designees to contact you
                at your e-mail address for the purpose of, among any other reason, to provide feedback on the operation
                of the Website and your user experience.</p>
              <h6 class="color-secondary-1">6. Charges and Billing.</h6>
              <p>You agree to pay for all Products that you purchase through the Website. You acknowledge and agree that
                SoStereo may charge your credit card for any Products purchased, and for any additional amounts
                (including any taxes and late fees, as applicable) as may be accrued by or in connection with your
                Account and you hereby authorize SoStereo to do so. For subscription services for which recurring
                billing may be applicable, SoStereo is hereby authorized to, and will, charge your credit card on the
                first day of each new billing cycle as outlined in the terms of the applicable billing agreement. You
                hereby agree to authorize SoStereo to charge your credit card for the above mentioned at SoStereo&#39;s
                convenience, but within (30) days of credit card authorization. YOU ARE RESPONSIBLE FOR PROVIDING
                SOSTEREO WITH A VALID CREDIT CARD AND THE TIMELY PAYMENT OF ALL FEES AND IN THE EVENT YOU FAIL TO DO SO,
                SOSTEREO SHALL BE UNDER NO OBLIGATION TO PROVIDE SERVICES HEREUNDER AND MAY TERMINATE YOUR ACCOUNT IN
                ITS SOLE DISCRETION. At its discretion, SoStereo may post charges individually or aggregate your charges
                with other purchases you make on the Website. If any of your billing information changes, you are
                responsible for promptly updating the information in the applicable section of your member account so
                that you can continue the uninterrupted use of the Website. You are responsible for any charges on your
                Account that are incurred by any person through your Account, and you are solely responsible for keeping
                your Account secure and confidential.</p>
              <p>SoStereo may, at its discretion, modify, suspend, or discontinue the Website (or any part or content
                thereof) at any time with or without notice to you, and SoStereo will not be liable to you or to any
                third party should it exercise such rights; provided that if SoStereo terminates any prepaid
                subscription service, SoStereo will refund a prorated amount of such prepaid fees.</p>
              <p>You may not cancel your yearly or monthly membership for subscription services during the initial term
                of such services and shall not be entitled to any refunds in the event you attempt to do so or do not
                otherwise take advantage of your subscription; however, you may give notice of nonrenewal for the
                following term. Upon any expiration or termination of your subscription service, you will no longer have
                access or right to any membership benefits under such subscription service.</p>
              <h6 class="color-secondary-1">7. Limitation on Sales</h6>
              <p>SoStereo sells Products to end user customers only, and not to resellers, distributors, music dealers,
                exporters, wholesalers, or any other businesses who have an intent to resell in any manner. You agree
                that you have no intention to, and shall not, resell any products obtained hereunder. SoStereo reserves
                the right to refuse access to the Website or sale of Products for any reason, to any end user at any
                time. SoStereo may terminate any offers for free or special promotions or pricing on merchandise at any
                time.</p>
              <h6 class="color-secondary-1">8. Right to Change Prices and Product Availability.</h6>
              <p>Prices and availability of Products offered through the Website are subject to change at any time.
                SoStereo does not provide price protection or refunds in the event of a price drop or promotional
                offering.</p>
              <h6 class="color-secondary-1">9. No Refunds.</h6>
              <p>All sales are final and all charges from those sales are nonrefundable. SoStereo will not refund any
                fees for Products or services that you purchase and then fail to download or otherwise utilize, unless
                such failure is caused solely by SoStereo.</p>
              <h6 class="color-secondary-1">10. No Responsibility for Typographical or Mathematical Errors.</h6>
              <p>Due to the rapidly expanding, broad selection of Content on the Website and the sophisticated pricing
                mechanisms, it is possible that Content may be inadvertently mispriced or miscalculated. In such an
                event, SoStereo reserves the right not to accept end user orders with prices based upon typographical or
                mathematical errors.</p>
              <h6 class="color-secondary-1">11. Taxes.</h6>
              <p>You are responsible for paying any governmental taxes imposed on your purchases, including, but not
                limited to, sales, use or value-added taxes.</p>
              <h6 class="color-secondary-1">12. Charges and Billing.</h6>
              <p>SoStereo currently accepts PayPal and most credit and debit cards, including Visa, MasterCard, American
                Express, Discover, JCB and Diner&#39;s Club. SoStereo does not accept cash, money orders, or checks.
                SoStereo reserves the right to change this policy at any time. You agree to pay all fees and charges
                associated with your purchase(s), (including any applicable taxes), at the rates in effect when the
                charges were incurred. You, not SoStereo, are responsible for any unauthorized amounts billed to your
                credit card by a third party. As a cardholder, your card issuer agreement governs your use of the
                designated card, and as such, you must refer to that agreement to determine your rights and liabilities
                as a cardholder. Obligation to pay for a License (as defined below) will survive your termination of
                your SoStereo Account.</p>
              <h6 class="color-secondary-1">13. Currency.</h6>
              <p>SoStereo accepts the following currencies: US Dollars. The currency displayed and accepted for each
                individual purchase is solely based on your territory of sale and cannot be modified by the end user.
                All purchases made shall be processed by SoStereo Music Group LLC. Any issues occurring with your
                purchase should be directed to the Customer Support team identified on the Website.</p>
              <h6 class="color-secondary-1">14. Limitations of Content Usage and Copyright.</h6>
              <p>The Website allows you to purchase licenses to specific songs for specific uses (each, a &#39;Song&#39;).
                In order to download a Song, you will be required to agree to the specific defined usage terms for the
                Song and to purchase a license that authorizes, and limits, the use of the Song to those exact terms. A
                PDF file of the standard company license document (&#39;License&#39;) will be sent to you and will serve
                as both invoice and binding legal license. The provisions of the License will control your use of the
                Song and will override any conflicting provisions in these terms with respect to your use of the
                Song.</p>
              <p>As between SoStereo and you, all Songs and all Content, along with all rights therein not expressly
                granted in writing in a License or these terms remain the sole and exclusive property of SoStereo.</p>
              <p>
                Other than as specifically and expressly permitted in the particular License, without limitation of any
                other rights or provisions of this Agreement, it is specifically understood and agreed that you may not:
                • Sublicense, re-license, rent, or lease any Song
                • Copy or publish any Song to a network, bulletin board, file sharing facilitator, or other unauthorized
                location
                • Distribute or allow any Song to be distributed to or used by anyone other than the authorized users
                • Use the Website or any Song in connection with any form of pornography or for any criminal activity or
                • use any Song in a libelous, defamatory, fraudulent, infringing or otherwise illegal manner
                • Use any Song to promote a business that sells or licenses Songs, or otherwise competes with SoStereo
                in any manner
                • Ship, transfer or export any of the Songs into any country or use any of the Songs in any manner
                prohibited by any export laws, restrictions or regulations.
              </p>
              <p>A &#39;Sample&#39; is a promotional portion of a Product, in some cases an entire Product, which is
                made available to you while you are logged onto the Website for evaluation purposes only. Samples are
                offered at no cost to you, and you may play as many Samples as you like. You may not attempt, or support
                others&#39; attempts, to download, copy, distribute, alter or capture a Sample.</p>
              <h6 class="color-secondary-1">15. Loss of Rights by SoStereo.</h6>
              <p>SoStereo may at any time lose the right to make certain Products available. In such an event, you will
                no longer be able to access such Products from SoStereo&#39;s Library function or obtain them from the
                Website catalog.</p>
              <h6 class="color-secondary-1">16. Electronic Signatures and Contracts.</h6>
              <p>Your use of the Website and establishment of an Account includes the ability to enter into agreements
                and/or to make purchases electronically. You agree that any submissions you make for electronic
                purchases constitute your intent and agreement to be bound by the terms of this Agreement and to pay for
                such purchases. Your agreement and intent to be bound by electronic submissions applies to all records
                relating to all transactions you enter into on the Website, including notices of cancellation, policies,
                contracts, and applications.</p>
              <h6 class="color-secondary-1">17. SoStereo&#39;s Rights.</h6>
              <p>By uploading content, inputting data, or engaging in any other form of communication through the
                Website, you agree that SoStereo may copy, sub-license, adapt, transmit, publicly perform or display any
                such content to provide and/or promote the Website and/or to respond to any legal requirement, claim or
                threat. If SoStereo&#39;s use of such content exploits any proprietary rights you may have in such
                material, you agree that SoStereo has an unrestricted, royalty-free, non-exclusive and perpetual
                worldwide right to do so. You represent and warrant that you own or otherwise control all applicable
                rights to the content and any other material that you post, upload, transmit or display; that such are
                accurate; that use of the such does not violate or infringe upon third-party rights and will not cause
                injury to any person or entity; and that you will defend indemnify and hold harmless SoStereo, its
                partners, parents, subsidiaries, employees, representatives, agents, affiliates and/or licensors, as
                applicable, for all claims resulting from content, materials and the like that you supply. You agree
                that any loss or damage of any kind that occurs as a result of the use of any content or material that
                you upload, post, transmit, display or otherwise make available through your use of the Website is
                solely your responsibility.</p>
              <h6 class="color-secondary-1">18. Privacy.</h6>
              <p>For additional information regarding SoStereo&#39;s use of information collected in connection with the
                Website, please refer to SoStereo&#39;s Privacy Policy, which is incorporated into these Terms and
                Conditions for reference: Visit Privacy Policy.</p>
              <h6 class="color-secondary-1">19. Copyrights.</h6>
              <p>All copyrights in and to the Website, including but not limited to, the SoStereo music Sync Licensing
                Platform (including the compilation of content), are owned by SoStereo and/or its licensors. The use of
                the Website, or any Content on the Website, except for use as expressly permitted in this Agreement, is
                prohibited and subject to all available civil and criminal remedies and penalties, including but not
                limited to monetary damages, for copyright infringement. All rights not expressly granted to you in this
                Agreement are reserved to SoStereo and/or its licensors.</p>
              <h6 class="color-secondary-1">20. Trademarks.</h6>
              <p>All trademarks, service marks, trade names, slogans, logos, and other indicia of origin that appear on
                or in connection with the Website are the property of SoStereo and/or its affiliates or licensors. You
                may not copy, display or use any of these marks without prior written permission of the mark owner. All
                rights not expressly granted to you in this Agreement are reserved to SoStereo and/or its licensors.</p>
              <h6 class="color-secondary-1">21. Violation of Intellectual Property Rights.</h6>
              <p>SoStereo respects the intellectual property and all associated legal rights of others, and we ask that
                our users do the same. If SoStereo receives a notice alleging that you have engaged in behavior that
                infringes SoStereo&#39;s or an other&#39;s intellectual property rights or reasonably suspects the same,
                SoStereo may suspend or terminate your Account without notice to you, and shall have no liabilities to
                you, past or present, for suspension or termination under this paragraph. We take alleged copyright
                infringement seriously and it is our policy to respond to appropriately detailed notices of alleged
                copyright or other forms of infringement that comply with the applicable international intellectual
                property laws (including, in the United States, the Digital Millennium Copyright Act). If you believe
                that any information or material on our Site constitutes copyright infringement, please send our
                Copyright Agent an e-mail or other written notice that includes the following information: (a) an
                electronic or physical signature of a person authorized to act on behalf of the owner of the exclusive
                right that is allegedly infringed; (b) identification of the copyrighted work claimed to have been
                infringed; (c) a description of where the material that you claim is infringing is located on the Site;
                (d) your address, telephone number, and e-mail address; (e) a statement by you that you have a good
                faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and
                (f) a statement by you, under penalty of perjury, that the information in your notice is accurate and
                that you are the copyright owner or authorized to act on the copyright owner&#39;s behalf. Our Copyright
                Agent for notice of claims of copyright infringement on the Website is: SoStereo Music Group LLC , Attn:
                Copyrights 2875 NE 191st Street; Suite 800B, Miami FL 33180 Infringing material will be taken down from
                the Website and repeat offenders will, at our option, have their user accounts and/or access to the
                Website removed or disabled. If SoStereo removes Content or disables or removes your user account or any
                Content or other materials you have posted or made available to us, and you believe this was done in
                error, please provide SoStereo with a written and detailed notice stating such counter-claim, provide
                any supporting facts and/or documentation necessary to establish your rights, and include your
                electronic or physical signature. Said notice should be delivered to the agent referenced above.</p>
              <h6 class="color-secondary-1">22. Enforcement of These Terms.</h6>
              <p>
                SoStereo reserves the right to enlist and take measures that SoStereo believes are reasonably necessary
                to enforce, or appropriate to enforce, or verify compliance with any part of this Agreement (including
                but not limited to SoStereo&#39;s right to cooperate with any legal process relating to your use of the
                Website and/or Content, and/or a third party claim that your use of the Website and/or Content is
                unlawful and/or infringes such third party&#39;s rights). You agree that
                SoStereo has the right, without liability or notice to you, to disclose any Registration Data/Account
                information to law enforcement authorities, government official, and/or any third party, as SoStereo
                believes is reasonably necessary or appropriate to enforce and/or verify compliance with any part of
                this Agreement (including but not limited to SoStereo&#39;s right to cooperate with any legal process
                relating to your use of the Website and/or Content, and/or a third party claim
                that your use of the Website and /or Content is unlawful and/or infringes such third party&#39;s
                rights).
              </p>
              <h6 class="color-secondary-1">23. Indemnity and Waiver.</h6>
              <p>
                By using the Website you agree to defend, indemnify and hold harmless SoStereo and its agents,
                employees, directors, contractors, representatives, attorneys, accountants, licensors, technology
                providers, affiliates, parents and subsidiaries from and against any and all claims, losses, demand,
                causes of action and judgments (including attorney&#39; fees and court costs) arising from or concerning
                your breach of any portion of this Agreement and/or your use of the Website or the
                Content and to reimburse them on demand for any losses, costs or expenses they incur as a result
                thereof. You shall not sue or seek to recover any damages or other payment from SoStereo and its agents,
                representative, employees, Licensors, content and technology providers, and any parent or subsidiary
                company as a result of its decision to terminate or suspend the Agreement and service hereunder.
              </p>
              <h6 class="color-secondary-1">24. Termination/Cancellation.</h6>
              <p>If you fail, or SoStereo suspects that you have failed, to comply with any of the provisions of this
                Agreement, including but not limited to: failure to make payment of fees due, failure to provide
                SoStereo with a valid credit card or with accurate and complete Registration Data, failure to safeguard
                your Account information, using your account for activities SoStereo believes violate the intentions or
                integrity of its platform, or violation of these Terms and Conditions or any license to the software,
                SoStereo, at its sole discretion, without notice to you may: (i) terminate this Agreement and /or your
                Account, and you will remain liable for all amounts due under your Account up to and including the date
                of termination; and/or (ii) preclude your access to the Website (or any part thereof), and seek any
                other lawful remedy available.</p>
              <h6 class="color-secondary-1">25. Governing Law.</h6>
              <p>The laws of the State of Florida, of the United States of America, excluding its conflicts of law
                rules, govern this Agreement and your use of the Website. Your use of the Website may also be subject to
                other local, state, national, or international laws. You expressly agree that exclusive jurisdiction for
                any claim or dispute with SoStereo or relating in any way to your use of the Website resides in the
                courts of the State of Florida, U.S.A.</p>
              <h6 class="color-secondary-1">26. Disclaimers and Limitations of Liability.</h6>
              <p>(a) You agree that from time to time SoStereo may remove the Website for indefinite periods of time, or
                cancel the Website at any time, with or without notice to you.</p>
              <p>(b) Under no circumstance shall SoStereo be liable for any unauthorized use of the Website or its
                Content.</p>
              <p>(c) To the extent that in a particular circumstance any disclaimer or limitation on damages or
                liability set forth herein is prohibited by applicable law, then, instead of the provisions hereof in
                such particular circumstance, SoStereo shall be entitled to the maximum disclaimers and/or limitation on
                damages and legality available at law or in equity by such applicable law in such particular
                circumstance, and in no event shall such damages or liability exceed US $10.</p>
              <p>(d) All Content on this Website is provided to you on an &#34;as is&#34; &#34;as available&#34; basis
                without warranty of any kind either express or implied, including but not limited to the implied
                warranties of merchantability, fitness for a particular purpose, and non-infringement. Company makes no
                warranty as to the accuracy, completeness, currency, or reliability of any content available through the
                website. You are responsible for verifying any information before relying on it. Use of the Website and
                the Content available on the Website is at your sole risk. Company makes no representations or
                warranties that use of the website will be uninterrupted or error-free. You are responsible for taking
                all necessary precautions to ensure that any content you may obtain from the Website is free of
                viruses.</p>
              <p>(e) Company specifically disclaims any liability, whether based in contract, tort, strict liability or
                otherwise, for any direct, indirect, incidental, consequential, or special damages arising out of or in
                any way connected with access to or use of the Website, even if Company has been advised of the
                possibility of such damages, including but not limited to reliance by any party on any content obtained
                through the use of the website, or that arises in connection with mistakes or omissions in, or delays in
                transmission of, information or content to or from the user, interruptions in telecommunications
                connections to the website or viruses, whether caused in whole or in part by negligence, acts of God,
                telecommunications failure, theft or destruction of, or unauthorized access to the website, or related
                information or programs.</p>
              <h6 class="color-secondary-1">27. Proprietary Rights.</h6>
              <p>SoStereo is a trademark of SoStereo in the United States. Other trademarks, names and logos on this
                Site are the property of their respective owners.</p>
              <p>Unless otherwise specified in these Terms, all information and screens appearing on this Site,
                including documents, services, site design, text, graphics, logos, images and icons, as well as the
                arrangement thereof, are the sole property of SoStereo, Copyright © [12/17/2014] SoStereo. All rights
                not expressly granted herein are reserved. Except as otherwise required or limited by applicable law,
                any reproduction, distribution, modification, retransmission, or publication of any copyrighted material
                is strictly prohibited without the express written consent of the copyright owner or license.</p>
              <h6 class="color-secondary-1">28. Feedback.</h6>
              <p>
                If you send or transmit any communications, comments, questions, suggestions, or related materials to
                SoStereo, whether by letter, email, telephone, or otherwise (collectively, “Feedback”), suggesting or
                recommending changes to the Website, Content, or materials, including, without limitation, new features
                or functionality relating thereto, all such Feedback is, and will be treated as, non-confidential and
                non-proprietary. You hereby assign all right, title, and interest in, | and SoStereo is free to use,
                without any attribution or compensation to you, any ideas, know-how, concepts, techniques, or other
                intellectual property and proprietary rights contained in the Feedback, whether or not patentable, for
                any purpose whatsoever, including but not limited to, developing, manufacturing, having manufactured,
                licensing, marketing, and selling, directly or indirectly, products and services using such Feedback.
                You understand and agree that SoStereo is
                not obligated to use, display, reproduce, or distribute any such ideas, know-how, concepts, or
                techniques contained in the Feedback, and you have no right to compel such use, display, reproduction,
                or distribution.
              </p>
              <h6 class="color-secondary-1">29. Dispute Resolution and Arbitration; Class Action Waiver.</h6>
              <p>Please read this carefully. It affects your rights.</p>
              <p>Most customer concerns can be resolved quickly and to a customer’s satisfaction by contacting us at <a
                  class="color-secondary-1" href="mailto:support@sostereomusic.com" target="_top">support&#64;sostereomusic.com</a>.
                This Provision facilitates the prompt and efficient resolution of any disputes that may arise between
                you and SoStereo. Arbitration is a form of private dispute resolution in which persons with a dispute
                waive their rights to file a lawsuit, to proceed in court and to a jury trial, and instead submit their
                disputes to a neutral third person (or arbitrator) for a binding decision. You have the right to opt-out
                of this Provision (as explained below), which means you would retain your right to litigate your
                disputes in a court, either before a judge or jury.</p>
              <p>Please read this provision carefully. It provides that all Disputes between you and SoStereo shall be
                resolved by binding arbitration. Arbitration replaces the right to go to court. In the absence of this
                arbitration agreement, you may otherwise have a right or opportunity to bring claims in a court, before
                a judge or jury, and/or to participate in or be represented in a case filed in court by others
                (including, but not limited to, class actions). Except as otherwise provided, entering into this
                agreement constitutes a waiver of your right to litigate claims and all opportunity to be heard by a
                judge or jury. There is no judge or jury in arbitration, and court review of an arbitration award is
                limited. The arbitrator must follow this agreement and can award the same damages and relief as a court
                (including attorney’s fees).</p>
              <p>For the purpose of this Provision, “SoStereo” means SoStereo and its parents, subsidiary, and affiliate
                companies, and each of their respective officers, directors, employees, and agents. The term “Dispute”
                means any dispute, claim, or controversy between you and SoStereo regarding any aspect of your
                relationship with SoStereo, whether based in contract, statute, regulation, ordinance, tort (including,
                but not limited to, fraud, misrepresentation, fraudulent inducement, or negligence), or any other legal
                or equitable theory, and includes the validity, enforceability or scope of this Provision (with the
                exception of the enforceability of the Class Action Waiver clause below). “Dispute” is to be given the
                broadest possible meaning that will be enforced, and shall include any claims against other parties
                relating to services or products provided or billed to you (such as SoStereo’s licensors, suppliers,
                dealers or third-party vendors) whenever you also assert claims against us in the same proceeding.</p>
              <p>WE EACH AGREE THAT, EXCEPT AS PROVIDED BELOW, ANY AND ALL DISPUTES, AS DEFINED ABOVE, WHETHER PRESENTLY
                IN EXISTENCE OR BASED ON ACTS OR OMISSIONS IN THE PAST OR IN THE FUTURE, WILL BE RESOLVED EXCLUSIVELY
                AND FINALLY BY BINDING ARBITRATION RATHER THAN IN COURT IN ACCORDANCE WITH THIS PROVISION.</p>
              <h6>Pre-Arbitration Claim Resolution</h6>
              <p>For all Disputes, whether pursued in court or arbitration, you must first give SoStereo an opportunity
                to resolve the Dispute. You must commence this process by mailing written notification to SoStereo, <a
                    class="color-secondary-1" href="mailto:support@sostereomusic.com" target="_top">support&#64;sostereomusic.com</a>.
                That written notification must include (1) your name, (2) your address, (3) a written description of
                your Claim, and (4) a description of the specific relief you seek. If SoStereo does not resolve the
                Dispute within 45 days after it receives your written notification, you may pursue your Dispute in
                arbitration. You may pursue your Dispute in a court only under the circumstances described below.</p>
              <h6>Exclusions from Arbitration/Right to Opt Out</h6>
              <p>Notwithstanding the above, you or SoStereo may choose to pursue a Dispute in court and not by
                arbitration if (a) the Dispute qualifies, it may be initiated in small claims or other appropriate court
                with proper jurisdiction; or (b) YOU OPT-OUT OF THESE ARBITRATION PROCEDURES WITHIN 30 DAYS FROM THE
                DATE THAT YOU FIRST CONSENT TO THIS AGREEMENT (the “Opt-Out Deadline”). You may opt out of this
                Provision by mailing written notification to SoStereo Music Group LLC; 2875 NE 191st Street; Suite 800B,
                Miami FL 33180. Your written notification must include (1) your name, (2) your address, and (3) a clear
                statement that you do not wish to resolve disputes with SoStereo through arbitration. Your decision to
                opt-out of this Arbitration Provision will have no adverse effect on your relationship with SoStereo.
                Any opt-out request received after the Opt-Out Deadline will not be valid and you must pursue your
                Dispute in arbitration or small claims court.</p>
              <h6>Arbitration Procedures</h6>
              <p>If this Provision applies and the Dispute is not resolved as provided above (Pre-Arbitration Claim
                Resolution) either you or SoStereo may initiate arbitration proceedings. The American Arbitration
                Association (“AAA”), www.adr.org, or JAMS, www.jamsadr.com, will arbitrate all Disputes, and the
                arbitration will be conducted before a single arbitrator. The arbitration shall be commenced as an
                individual arbitration, and shall in no event be commenced as a class arbitration. All issues shall be
                for the arbitrator to decide, including the scope of this Provision.</p>
              <p>For arbitration before AAA, for Disputes of less than $75,000, the AAA’s Supplementary Procedures for
                Consumer-Related Disputes will apply; for Disputes involving $75,000 or more, the AAA’s Commercial
                Arbitration Rules will apply. In either instance, the AAA’s Optional Rules For Emergency Measures Of
                Protection shall apply. The AAA rules are available at www.adr.org or by calling 1-800-778-7879. For
                arbitration before JAMS, the JAMS Comprehensive Arbitration Rules & Procedures and the JAMS Recommended
                Arbitration Discovery Protocols For Domestic, Commercial Cases will apply. The JAMS rules are available
                at www.jamsadr.com or by calling 1-800-352-5267. This Provision governs in the event it conflicts with
                the applicable arbitration rules. Under no circumstances will class action procedures or rules apply to
                the arbitration.</p>
              <p>Because the Site and these Terms concern interstate commerce, the Federal Arbitration Act (“FAA”)
                governs the arbitrability of all Disputes. However, the arbitrator will apply applicable substantive law
                consistent with the FAA and the applicable statute of limitations or condition precedent to suit.</p>
              <p>Arbitration Award – The arbitrator may award on an individual basis any relief that would be available
                pursuant to applicable law, and will not have the power to award relief to, against or for the benefit
                of any person who is not a party to the proceeding. The arbitrator will make any award in writing but
                need not provide a statement of reasons unless requested by a party. Such award will be final and
                binding on the parties, except for any right of appeal provided by the FAA, and may be entered in any
                court having jurisdiction over the parties for purposes of enforcement.</p>
              <p>Location of Arbitration – You or SoStereo may initiate arbitration in Miami-Dade County, Florida or the
                federal judicial district that includes your billing address. In the event that you select the federal
                judicial district that includes your billing address, SoStereo may transfer the arbitration to
                Miami-Dade County, Florida in the event that it agrees to pay any additional fees or costs you incur as
                a result of the transfer, as determined by the arbitrator.</p>
              <p>Payment of Arbitration Fees and Costs – SoStereo will pay all arbitration filing fees and arbitrator’s
                costs and expenses upon your written request given prior to the commencement of the arbitration. You are
                responsible for all additional fees and costs that you incur in the arbitration, including, but not
                limited to, attorneys or expert witnesses. Fees and costs may be awarded as provided pursuant to
                applicable law. In addition to any rights to recover fees and costs under applicable law, if you provide
                notice and negotiate in good faith with SoStereo as provided in the section above titled
                “Pre-Arbitration Claim Resolution” and the arbitrator concludes that you are the prevailing party in the
                arbitration, you will be entitled to recover reasonable attorney’s fees and costs as determined by the
                arbitrator.</p>
              <h6>Class Action Waiver</h6>
              <p>Except as otherwise provided in this Provision, the arbitrator may not consolidate more than one
                person’s claims, and may not otherwise preside over any form of a class or representative proceeding or
                claims (such as a class action, consolidated action or private attorney general action) unless both you
                and SoStereo specifically agree to do so following initiation of the arbitration. If you choose to
                pursue your Dispute in court by opting out of the Arbitration Provision, as specified above, this Class
                Action Waiver will not apply to you. Neither you, nor any other user of the Site can be a class
                representative, class member, or otherwise participate in a class, consolidated, or representative
                proceeding without having complied with the opt-out requirements above.</p>
              <h6>Jury Waiver</h6>
              <p>You understand and agree that by entering into this Agreement you and SoStereo are each waiving the
                right to a jury trial or a trial before a judge in a public court. In the absence of this Provision, you
                and SoStereo might otherwise have had a right or opportunity to bring Disputes in a court, before a
                judge or jury, and/or to participate or be represented in a case filed in court by others (including
                class actions). Except as otherwise provided below, those rights are waived. Other rights that you would
                have if you went to court, such as the right to appeal and to certain types of discovery, may be more
                limited or may also be waived.</p>
              <h6>Severability</h6>
              <p>If any clause within this Provision (other than the Class Action Waiver clause above) is found to be
                illegal or unenforceable, that clause will be severed from this Provision, and the remainder of this
                Provision will be given full force and effect. If the Class Action Waiver clause is found to be illegal
                or unenforceable, this entire Provision will be unenforceable and the Dispute will be decided by a
                court.</p>
              <h6>Continuation</h6>
              <p>This Provision shall survive the termination of your service with SoStereo or its affiliates.
                Notwithstanding any provision in this Agreement to the contrary, we agree that if SoStereo makes any
                change to this Provision (other than a change to the Notice Address), you may reject any such change and
                require SoStereo to adhere to the language in this Provision if a dispute between us arises.</p>
              <h6 class="color-secondary-1">30. Recaptcha</h6>
              <p>This site is protected by reCAPTCHA and the Google 
                <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
                <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
              <h6 class="color-secondary-1">31. General.</h6>
              <p>SoStereo prefers to advise you if we feel you are not complying with these Terms and to recommend any
                necessary corrective action. However, certain violations of these Terms, as determined by SoStereo, may
                result in immediate termination of your access to this Site without prior notice to you. The Federal
                Arbitration Act, Florida state law and applicable U.S. federal law, without regard to the choice or
                conflicts of law provisions, will govern these Terms. Foreign laws do not apply. Except for Disputes
                subject to arbitration as described above, any disputes relating to these Terms or this Site will be
                heard in the courts located in Miami-Dade County, Florida. If any of these Terms is found to be
                inconsistent with applicable law, then such term shall be interpreted to reflect the intentions of the
                parties, and no other terms will be modified. SoStereo’s failure to enforce any of these Terms is not a
                waiver of such term. These Terms are the entire agreement between you and SoStereo and supersede all
                prior or contemporaneous negotiations, discussions or agreements between you and SoStereo about this
                Site. The proprietary rights, disclaimer of warranties, representations made by you, indemnities,
                limitations of liability and general provisions shall survive any termination of these Terms.</p>
              <h6 class="color-secondary-1">32. Contact Us.</h6>
              <p>
                If you have any questions about these Terms or otherwise need to contact SoStereo for any reason, you
                can reach us at
                <a class="color-secondary-1" href="mailto:support@sostereomusic.com" target="_top">support&#64;sostereomusic.com</a>.
              </p>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</section>
