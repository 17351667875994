<div
  class="widget-container sidenav-padding"
  [ngStyle]="{
    'margin-left': margin || '0',
    'z-index': playerService.showSearchSidebar ? '6' : '4',
    visibility: playerWidgetService.showPlayer ? '' : 'hidden',
    bottom: hasBottomMenu ? '50px' : '0'
  }"
>
  <div class="row ml0 mr0">
    <div
      class="pl0 pr0"
      [ngClass]="
        deviceDetectorService.isMobile() || deviceDetectorService.isTablet()
          ? 'col-xs-12 col-sm-12 col-md-12'
          : 'col-xs-7 col-sm-7 col-md-7'
      "
    >
      <div class="audioPlayerWrapper">
        <div class="row ml0 mr0">
          <div
            class="pl0 pr0"
            [ngClass]="[
              showCloseControl ? 'col-md-2' : 'col-md-1',
              deviceDetectorService.isMobile() || deviceDetectorService.isTablet()
                ? 'col-xs-2 col-sm-2 col-md-2'
                : 'col-xs-3 col-sm-3'
            ]"
          >
            <div class="row ml0 mr0">
              <div class="col-sm-6 pl0 pr0 mobile-hidden" *ngIf="showCloseControl">
                <div class="controls">
                  <div (click)="onClosePressed()" style="background: transparent; height: inherit">
                    <span class="ss-icon-close center-elm-widget player-icon"></span>
                  </div>
                </div>
              </div>
              <div
                class="col-xs-12 pl0 pr0"
                [ngClass]="showCloseControl ? 'col-sm-6' : 'col-sm-12'"
              >
                <div class="controls">
                  <div
                    *ngIf="control.play && !control.loading"
                    (click)="onPlayPressed()"
                    style="background: transparent; height: inherit"
                  >
                    <span class="ss-icon-play_on center-elm-widget"></span>
                  </div>
                  <div
                    *ngIf="control.pause && !control.loading"
                    (click)="onPausePressed()"
                    style="background: transparent; height: inherit"
                  >
                    <span class="ss-icon-stop center-elm-widget"></span>
                  </div>
                  <div class="spinner center-elm-widget" *ngIf="control.loading">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5 hide-line"></div>
                    <div class="rect6 hide-line"></div>
                    <div class="rect7 hide-line"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="pl0 pr0"
            id="waveform-container"
            [ngClass]="[
              showCloseControl ? 'col-md-10' : 'col-md-11',
              deviceDetectorService.isMobile() || deviceDetectorService.isTablet()
                ? 'col-xs-10 col-sm-10'
                : 'col-xs-9 col-sm-9'
            ]"
          >
            <app-comment-list
              *ngIf="track?.comments && waveReady"
              [commentItems]="track?.comments"
              [duration]="this.control?.duration"
              [loggedUser]="loggedUser"
              [track]="track"
              (segmentRequest)="onSegmentRequest($event)"
              (updateCommentRequest)="onUpdateCommentRequest()"
              (loginRequest)="login()"
              #commentListComponent
            ></app-comment-list>
            <app-audio-analysis
              *ngIf="track?.segmentsGroups && waveReady"
              [rating]="track?.rating"
              [duration]="this.control?.duration"
              [loggedUser]="loggedUser"
              [track]="track"
              (segmentRequest)="onSegmentRequest($event)"
              (loginRequest)="login()"
              #audioAnalysisComponent
            ></app-audio-analysis>
            <app-new-segment
              [control]="control"
              *ngIf="(newSegment || showSelectSegment) && waveReady"
              [loggedUser]="loggedUser"
              [track]="track"
              [showSelectSegment]="showSelectSegment"
              (closeModal)="closeNewSegment($event)"
            ></app-new-segment>
            <app-new-comment
              [control]="control"
              *ngIf="waveReady"
              [loggedUser]="loggedUser"
              [track]="track"
              (commentPopUpOpened)="hideCommentList()"
              (updateCommentRequest)="onUpdateCommentRequest()"
              (loginRequest)="login()"
            ></app-new-comment>
            <div
              id="waveform"
              [ngClass]="{
                'audio-analysis-height': track?.segmentsGroups && waveReady,
                'mobile-height':
                  deviceDetectorService.isMobile() || deviceDetectorService.isTablet()
              }"
            ></div>
            <div
              *ngIf="deviceDetectorService.isMobile() || deviceDetectorService.isTablet()"
              class="mobile-song-info-wrapper"
            >
              <div class="pull-left">
                {{ track?.objectData?.songName || track?.name }}
              </div>
              <div style="color: grey">-</div>
              <div class="pull-right artist-text">
                <span class="song-artist-child" [ngClass]="{ 'moving-text': movingSongArtists }">
                  <span
                    class="artist-name"
                    *ngIf="track?.objectData?.artistName && !track?.artists"
                  >
                    {{ track?.objectData?.artistName }}</span
                  >
                  <ng-container *ngFor="let artist of track?.artists; let artistsIndex = index">
                    <span class="artist-name">{{ artist.name || artist.artistName }}</span>
                    <span *ngIf="artistsIndex !== track?.artists.length - 1">,&nbsp;</span>
                  </ng-container>
                </span>
              </div>
            </div>
            <div
              *ngIf="!(deviceDetectorService.isMobile() || deviceDetectorService.isTablet())"
              class="time-wrapper"
            >
              <div class="pull-left">{{ control.currentTime | wavesurferTimeFormat }}</div>
              <div class="pull-right">{{ control.duration | wavesurferTimeFormat }}</div>
            </div>
            <div id="wave-timeline"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-5 col-sm-5 col-md-5 pl0 pr0">
      <div class="row ml0 mr0">
        <div
          [ngClass]="!showLicenseButton() ? 'col-sm-12 col-md-12' : 'col-sm-9 col-md-9'"
          class="pl0 pr0 song-data-wrapper"
        >
          <div class="row ml0 mr0">
            <div
              class="col-sm-1 col-md-1 pl0 pr0 hidden-for-mobile"
              (mouseenter)="showVolumeSlider = true"
              (mouseleave)="showVolumeSlider = false"
            >
              <div (click)="toggleMute()" align="center">
                <i
                  class="fa fa-2x mt20"
                  *ngIf="volume > 0"
                  [ngClass]="{
                    'fa-volume-down': volume > 0 && volume <= 0.7,
                    'fa-volume-up': volume > 0.7
                  }"
                  aria-hidden="true"
                ></i>
                <span *ngIf="volume === 0" class="mute-icon mt20"></span>
              </div>
              <div
                *ngIf="isBrowser"
                class="player-widget-slider pt15 pb15"
                [hidden]="!showVolumeSlider"
                [@growAnimation]
              >
                <ngx-slider
                  [options]="options"
                  [(ngModel)]="volume"
                  (userChange)="setVolume($event.value)"
                ></ngx-slider>
              </div>
            </div>
            <div
              class="col-xs-12 song-info pt10 hidden-for-mobile"
              [ngClass]="track?.hideOptions ? 'col-sm-11 col-md-11' : 'col-sm-7 col-md-7'"
            >
              <p class="song-info-title mb0">
                <span class="song-title-child" [ngClass]="{ 'moving-text': movingSongTitle }">
                  {{ track?.objectData?.songName || track?.name | hideExtension }}
                  {{ track?.version ? ' (' + (track?.version | titlecase) + ')' : '' }}
                  {{
                    track?.version?.toLowerCase() !== 'instrumental' &&
                    track?.objectData?.hasOwnProperty('vocals') &&
                    !track?.objectData?.vocals
                      ? ' (Instrumental)'
                      : ''
                  }}
                  {{
                    track?.objectData?.hasOwnProperty('explicit') && track?.objectData?.explicit
                      ? ' (Explicit)'
                      : ''
                  }}
                </span>
              </p>
              <p class="current-song-artist">
                <span class="song-artist-child" [ngClass]="{ 'moving-text': movingSongArtists }">
                  <span
                    class="artist-name"
                    *ngIf="track?.objectData?.artistName && !track?.artists"
                  >
                    {{ track?.objectData?.artistName }}</span
                  >
                  <ng-container *ngFor="let artist of track?.artists; let artistsIndex = index">
                    <a
                      [routerLink]="
                        '/music-sync-licensing/artist/' + (artist.slug || artist.artistSlug)
                      "
                    >
                      <span class="artist-name">{{ artist.name || artist.artistName }}</span>
                      <span *ngIf="artistsIndex !== track?.artists.length - 1">,&nbsp;</span>
                    </a>
                  </ng-container>
                </span>
              </p>
            </div>
            <ng-container *ngIf="!track?.hideOptions">
              <div
                class="col-sm-2 col-md-2 btn-left-line song-icon hidden-for-mobile"
                (click)="dropdown.toggle(true)"
              >
                <div dropdown #dropdown="bs-dropdown" [autoClose]="false" [dropup]="true">
                  <a
                    dropdownToggle
                    id="button-dropup"
                    class="dropdown-toggle"
                    aria-controls="dropdown-dropup"
                    (click)="dropdown.toggle(true)"
                  >
                    <img src="./assets/images/ellipsis.svg" alt="More actions" />
                  </a>
                  <ul
                    id="dropdown-menu"
                    class="dropdown-menu"
                    role="menu"
                    aria-labelledby="dropdown-dropup"
                    *dropdownMenu
                  >
                    <li role="menuitem" *ngIf="isAllowed(['*'])">
                      <a class="dropdown-item" (click)="showPlayListBySong()">
                        <img
                          class="info-icon"
                          src="./assets/images/player-widget/playlist_add_check_circle.svg"
                          alt=""
                        />
                        <span>Featured in</span></a
                      >
                    </li>
                    <li role="menuitem" [appAllowedTo]="'GetSongCopyright'">
                      <a class="dropdown-item" (click)="showArtistCopyright()">
                        <i class="fa fa-info-circle info-icon" aria-hidden="true"></i>
                        <span>Rightholders Info</span></a
                      >
                    </li>
                    <li
                      role="menuitem"
                      *ngIf="trackData?.lyric && trackData?.lyric?.length > 0 && showLyricsOption"
                    >
                      <a class="dropdown-item" (click)="showSongLyric()">
                        <i class="fa fa-pencil-square-o info-icon" aria-hidden="true"></i>
                        <span>Lyric</span></a
                      >
                    </li>
                    <li role="menuitem">
                      <a class="dropdown-item" (click)="getShareLink()">
                        <i
                          class="material-icons info-icon"
                          aria-hidden="true"
                          [attr.data-icon]="'share'"
                        ></i>
                        <span>Copy song link</span></a
                      >
                    </li>
                    <li role="menuitem">
                      <a class="dropdown-item" (click)="loggedUser ? downloadTrack() : signUp()">
                        <i
                          class="material-icons info-icon"
                          aria-hidden="true"
                          [attr.data-icon]="'file_download'"
                        ></i>
                        <span>Download MP3</span>
                      </a>
                    </li>
                    <li
                      role="menuitem"
                      *ngIf="
                        isAllowed([
                          'UpdateSongFiles',
                          'UpdateSongAll',
                          'GetSongFilesOriginal',
                          '*'
                        ]) &&
                        ((track.objectData.files &&
                          track.objectData.files['original'] &&
                          !track.objectData.files['original'].includes('.mp3')) ||
                          (track.objectData.fileUrls &&
                            track.objectData.fileUrls['original'] &&
                            !track.objectData.fileUrls['original'].includes('.mp3')))
                      "
                    >
                      <a
                        class="dropdown-item"
                        (click)="loggedUser ? downloadTrack('original') : signUp()"
                      >
                        <i
                          class="material-icons info-icon"
                          aria-hidden="true"
                          [attr.data-icon]="'file_download'"
                        ></i>
                        <span>Download Hi-Res</span></a
                      >
                    </li>
                    <li
                      role="menuitem"
                      *ngIf="
                        isAllowed(['*']) &&
                        (track.objectData.stemsAvailable ||
                          track.objectData.customizable ||
                          track.objectData.internalManager ||
                          track.objectData.notes)
                      "
                    >
                      <a class="dropdown-item" (click)="showInternalNotes(track.objectData)">
                        <i class="fa fa-book info-icon" aria-hidden="true"></i>
                        <span>Internal notes</span></a
                      >
                    </li>
                    <li role="menuitem" *ngIf="isAllowed(['CreateInquirie'])">
                      <a class="dropdown-item" (click)="openPlacementModal()">
                        <i
                          class="material-icons info-icon"
                          aria-hidden="true"
                          [attr.data-icon]="'forum'"
                        ></i>
                        <span>Communicate with Artist</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="col-sm-2 col-md-2 btn-left-line song-icon hidden-for-mobile center-flex"
                *ngIf="track?.objectData"
                (click)="loggedUser ? addTrackToPlaylist() : signUp()"
              >
                <i
                  class="material-icons"
                  aria-hidden="true"
                  style="cursor: pointer"
                  [attr.data-icon]="'playlist_add'"
                ></i>
              </div>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="showLicenseButton()">
          <div
            class="col-sm-3 col-md-3 license-wrapper"
            (click)="loggedUser ? licenseRequestModalComponent.showModal() : signUp()"
          >
            <p class="license-text">License</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<app-custom-license-request-modal
  #customLicenseRequestModalComponent
  [track]="this.track"
></app-custom-license-request-modal>
<app-song-rightholders-info-modal
  #songRightholdersInfoModalComponent
></app-song-rightholders-info-modal>
<app-add-to-playlist-modal
  [isGeneric]="true"
  [emitResults]="true"
  (addToPlaylistSuccess)="onAddToPlaylistSuccess($event)"
  #addToPlaylistModalComponent
></app-add-to-playlist-modal>
<app-license-request-modal
  [trackInput]="track?.objectData"
  #licenseRequestModalComponent
></app-license-request-modal>
<app-song-internal-notes-modal #songInternalNotesModalComponent></app-song-internal-notes-modal>
<app-catalog-owner-communication-modal
  #catalogOwnerCommunicationModal
></app-catalog-owner-communication-modal>
