import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  PLATFORM_ID,
} from '@angular/core';
import WaveSurfer from 'wavesurfer.js';
import { clone, forEach, sortBy, isEqual, cloneDeep } from 'lodash-es';
import { interval, Subject, Subscription } from 'rxjs';
import { PlayerWidgetService } from '../../services/player-widget.service';
import { AddToPlaylistModalComponent } from '../../../modules/modals/components/add-to-playlist-modal/add-to-playlist-modal.component';
import { SongInternalNotesModalComponent } from '../../../modules/modals/components/song-internal-notes-modal/song-internal-notes-modal.component';
import {
  AuthenticationService,
  CommentsService,
  WaveformService,
  SongsService,
  FileService,
} from 'sostereo-services';
import { DownloadService } from '../../../shared/services/download.service';
import { ToastrService } from 'ngx-toastr';
import { CommentListComponent } from './comment-list/comment-list.component';
import { AudioAnalysisComponent } from './audio-analysis/audio-analysis.component';
import { TrackingService } from '../../../shared/services/tracking.service';
import { Options } from 'ngx-slider-v2';
import { PlayerWidgetTrackModel } from '../../../shared/models/player-widget-track.model';
import { animate, style, transition, trigger } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';
import { SongRightholdersInfoModalComponent } from '../../../modules/modals/components/song-rightholders-info-modal/song-rightholders-info-modal.component';
import { CommonService } from '../../../shared/services/common.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { PlayerService } from '../../../shared/services/player.service';
import { Location, isPlatformBrowser } from '@angular/common';
import { ClipboardService } from 'ngx-clipboard';
import { CatalogOwnerCommunicationModalComponent } from 'src/app/angular/modules/modals/components/catalog-owner-communication-modal/catalog-owner-communication-modal.component';
import { DeviceDetectorService } from 'ngx-device-detector';

declare const window;

@Component({
  selector: 'app-player-widget',
  templateUrl: './player-widget.component.html',
  styleUrls: ['./player-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('growAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.1s ease-in', style({ opacity: 1 }))]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.1s ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerWidgetComponent implements OnInit, OnDestroy {
  @Input() margin: String;
  @Input() hasBottomMenu: boolean;
  @ViewChild('addToPlaylistModalComponent', { static: true })
  addToPlaylistModal: AddToPlaylistModalComponent;

  @ViewChild('songInternalNotesModalComponent', { static: true })
  songInternalNotesModal: SongInternalNotesModalComponent;

  @ViewChild('commentListComponent') commentList: CommentListComponent;
  @ViewChild('audioAnalysisComponent') trackAnalysis: AudioAnalysisComponent;
  @ViewChild('songRightholdersInfoModalComponent', { static: true })
  songRightholdersInfoModal: SongRightholdersInfoModalComponent;

  @ViewChild('catalogOwnerCommunicationModal', { static: true })
  catalogOwnerCommunicationModal: CatalogOwnerCommunicationModalComponent;

  public wave: WaveSurfer = null;
  public audioUrl: string;
  public control = {
    loading: true,
    play: false,
    pause: false,
    duration: 0,
    currentTime: 0,
  };

  public controlIsReady = false;
  public volume = 0.65;
  public showVolumeSlider = false;
  public waveReady: boolean;
  public options: Options = {
    floor: 0,
    step: 0.01,
    ceil: 1,
    translate: () => {
      return '';
    },
    animate: false,
    vertical: true,
  };

  public subscribe;
  public track: PlayerWidgetTrackModel;
  public queue: PlayerWidgetTrackModel[];
  public newSegment = false;
  public wavePeaks = [];
  public waveWidth = 12000;
  public setPeaksReady = false;
  public audioprocess = 0;
  public source = interval(1000);
  public lastVolume = 0.65;
  public movingSongTitle = false;
  public movingSongArtists = false;
  public showCloseControl = true;
  public showLyricsOption = false;
  public loggedUser;
  public trackData;
  @Output() addToPlaylistSuccess = new EventEmitter();
  private play$: Subscription = new Subscription();
  private queueUpdated$: Subscription = new Subscription();
  private pause$: Subscription = new Subscription();
  private newTrack$: Subscription = new Subscription();
  private listComments$: Subscription = new Subscription();
  private updatedPlaylistId$: Subscription = new Subscription();
  private isFinished$: Subscription = new Subscription();
  private waveColor$: Subscription;
  public showSelectSegment: boolean = false;
  isBrowser: boolean;
  private destroyed$ = new Subject<void>();
  autoPlay: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    public playerWidgetService: PlayerWidgetService,
    public playerService: PlayerService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private commentsService: CommentsService,
    private trackingService: TrackingService,
    private waveformService: WaveformService,
    private downloadService: DownloadService,
    private commonService: CommonService,
    private songService: SongsService,
    private elementRef: ElementRef,
    private router: Router,
    private location: Location,
    private clipBoardService: ClipboardService,
    private fileService: FileService,
    public deviceDetectorService: DeviceDetectorService,
    @Inject('environment') private environment,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.waveReady = false;
    this.loggedUser = this.authenticationService.getCurrentUser();
    this.authenticationService.accessData.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      this.loggedUser = user;
    });

    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showCloseControl = !event.url.includes('/music-sync-licensing');
        this.showLyricsOption =
          event.url.includes('/music-sync-licensing') || event.url.includes('/discover');
        this.newSegment = event.url.includes('segmentsGroups');
      }
    });

    this.playerWidgetService.autoPlay.pipe(takeUntil(this.destroyed$)).subscribe((autoPlay) => {
      console.log('next autoplay', autoPlay);
      this.autoPlay = autoPlay;
    });
  }

  ngOnInit() {
    const sessionSettings = this.authenticationService.getUserSessionSettings();
    if (sessionSettings?.playerWidgetConfig?.hasOwnProperty('volume')) {
      this.volume = sessionSettings.playerWidgetConfig.volume;
    }

    this.showCloseControl = !this.router.url.includes('/music-sync-licensing');
    this.showLyricsOption =
      this.router.url.includes('/music-sync-licensing') || this.router.url.includes('/discover');
    this.newSegment = this.router.url.includes('segmentsGroups');

    this.onPreviewPressed();
    this.waveColor$ = this.commonService.waveColor.subscribe((color) => {
      if (color) {
        this.wave?.setOptions({ waveColor: color, progressColor: color });
      }
    });
    this.createPlaySubscribe();
    this.queueUpdated$ = this.playerWidgetService.queueUpdated.subscribe(() => {
      this.queue = this.playerWidgetService.queue;
    });
    this.pause$ = this.playerWidgetService.pause.subscribe(() => {
      this.control.pause = false;
      this.control.play = true;
      this.wave.pause();
    });
    this.listComments$ = this.playerWidgetService.listComments.subscribe((listComments) => {
      this.setComments(listComments);
    });
    this.updatedPlaylistId$ = this.playerWidgetService.updatedPlaylistId.subscribe((playlistId) => {
      if (this.track?.objectData) {
        this.track.objectData.playlistId = playlistId;
      }
    });
    this.createNewTrackSubscribe();
    this.createFinishedSubscribe();
  }

  createPlaySubscribe() {
    this.play$ = this.playerWidgetService.play.subscribe((track) => {
      this.trackPlayEvent(track);
      this.showSelectSegment = this.playerWidgetService.showSelectSegment;
      if (this.track) {
        if (
          isEqual(track.trackId, this.track.trackId) &&
          isEqual(track.url, this.track.url) &&
          isEqual(track.fileType, this.track.fileType) &&
          isEqual(track.segmentsGroups, this.track.segmentsGroups)
        ) {
          this.control.pause = true;
          this.control.play = false;
          if (this.wave) {
            // One case is when the first song is the same as the last one search
            if (!this.wave.isPlaying() && !this.autoPlay) {
              setTimeout(() => {
                this.control.loading = false;
                this.control.play = false;
                this.control.pause = true;
                //this.playerWidgetService.autoPlay = true;
                this.cdr.detectChanges();
              });
            } else {
              this.playPromise();
            }
          }
          if (track.rating) {
            this.track.rating = track.rating;
          }
        } else {
          this.setNewTrack(track);
        }
      } else {
        this.setNewTrack(track);
      }
    });
  }

  createNewTrackSubscribe() {
    this.newTrack$ = this.playerWidgetService.newTrack.subscribe(() => {
      if (this.wave) {
        this.wave.stop();
        this.wave.setVolume(this.volume);
      }
      this.waveReady = false;
      this.track = this.playerWidgetService.track;
      if (
        this.track.url &&
        this.track.url !== undefined &&
        this.track.url.indexOf('@streaming.mp3') === -1
      ) {
        // This is to change extension in case is one of these
        const audioExt = ['.aiff', '.aif', '.aifc', '.wav'];
        for (let i = 0; i < audioExt.length; i++) {
          if (this.track.url && this.track.url.includes(audioExt[i])) {
            const urlSplit = this.track.url.split(audioExt[i]);
            this.track.url = `${urlSplit[0]}@streaming.mp3`;
            break;
          }
        }
      }
      setTimeout(() => {
        const songInfoContainer = this.elementRef.nativeElement.querySelector('.song-info-title');
        const songTitle = this.elementRef.nativeElement.querySelector('.song-title-child');
        const songArtist = this.elementRef.nativeElement.querySelector('.song-artist-child');
        this.movingSongTitle = songTitle.offsetWidth > songInfoContainer.offsetWidth;
        this.movingSongArtists = songArtist.offsetWidth > songInfoContainer.offsetWidth;
      });
      this.audioUrl = clone(this.track.url);
      if (this.wave) {
        Promise.resolve()
          .then(() => {
            // Cancel other audio/media elements requests
            if (this.track.url && document.getElementsByTagName('audio').length > 0) {
              const v = document.getElementsByTagName('audio')[0];
              v.src = '';
              this.cdr.detectChanges();
            }
            if (this.track.hideOptions) {
              console.log('Get audio 1');
              this.fileService.getBlobFileByUrl(this.audioUrl + '?ngsw-bypass=true').then((res) => {
                this.wave.load(res);
              });
            } else {
              console.log('Get audio 2');
              this.wave.load(this.audioUrl + '?ngsw-bypass=true', [[]]).catch((err) => {
                console.error(err);
              });
            }

            if (this.track && this.track.trackId && !this.track.hideOptions) {
              this.waveformService
                .get(
                  this.track.waveformUrl ? this.track.waveformUrl : this.track.url,
                  this.track.waveformUrl ? true : false,
                )
                .pipe(finalize(() => this.cdr.detectChanges()))
                .subscribe({
                  next: (res) => {
                    if (res && res.body) {
                      const element: any = document.getElementsByClassName('audioPlayerWrapper')[0];
                      let width = element.offsetWidth;
                      if (this.commonService.isRetina() || this.commonService.isHighDensity()) {
                        width = width * 2;
                      }
                      this.waveWidth = width * 0.9;
                      this.wavePeaks = res.body.peaks;
                      console.log('Get audio 3');
                      this.wave
                        .load(this.audioUrl + '?ngsw-bypass=true', this.wavePeaks)
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  },
                  error: (err) => {
                    console.log('Error at getSongWaveform', err);
                    this.trackingService.track('getSongWaveform error', {
                      fileName: this.track.name,
                      fileUrl: this.track.url,
                      trackId: this.track.trackId,
                      error: err,
                    });
                  },
                });
            }
          })
          .catch((error) => {
            console.log('Catch Load Error >>> ', error, this.wavePeaks);
            this.onPlayPressed();
            //this.wave.drawer.drawPeaks(this.wavePeaks, this.waveWidth);
          });
      }
    });
  }

  createFinishedSubscribe() {
    this.isFinished$ = this.playerWidgetService.isFinished.subscribe(() => {
      if (this.track.objectData && this.queue) {
        const previousTrack = this.queue.find(
          (track) =>
            (track.trackId === this.track.trackId &&
              !this.track.objectData.hasOwnProperty('originalTrackIdQueue')) ||
            (this.track.objectData.hasOwnProperty('originalTrackIdQueue') &&
              this.track.objectData.originalTrackIdQueue === track.trackId),
        );
        if (this.track.objectData.hasOwnProperty('originalTrackIdQueue')) {
          delete this.track.objectData.originalTrackIdQueue;
        }
        const previousTrackIndex = this.queue.indexOf(previousTrack);
        let nextTrack = new PlayerWidgetTrackModel();
        if (previousTrackIndex < this.queue.length - 1) {
          nextTrack = this.queue[previousTrackIndex + 1];
        } else {
          nextTrack = this.queue[0];
        }
        this.updatePaging(nextTrack);
        this.playerWidgetService.playSong(nextTrack);
        this.playerWidgetService.songPlaying(nextTrack);
      }
    });
  }

  private updatePaging(nextTrack) {
    if (this.router.url.includes(`music-sync-licensing/search`) && nextTrack?.paging?.page) {
      let params = this.commonService.getQueryParamsFromUrl();
      params = { ...params, ...nextTrack?.paging };
      const urlTree = this.router.createUrlTree([], {
        queryParams: params,
        queryParamsHandling: 'merge',
      });
      this.location.go(urlTree.toString());
    }
  }

  ngOnDestroy() {
    this.cdr.detach();
    if (this.isBrowser) {
      this.onStopPressed();
    }
    if (this.subscribe) {
      setTimeout(() => this.subscribe.unsubscribe());
    }
    this.play$.unsubscribe();
    this.pause$.unsubscribe();
    this.newTrack$.unsubscribe();
    this.queueUpdated$.unsubscribe();
    this.listComments$.unsubscribe();
    this.updatedPlaylistId$.unsubscribe();
    this.isFinished$.unsubscribe();
    this.subscribe?.unsubscribe();
    this.waveColor$?.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
    this.cdr.detectChanges();
  }

  public trackPlayEvent(track) {
    if (!this.trackData || (this.trackData && this.trackData?.trackId !== track?.trackId)) {
      this.trackData = cloneDeep(track);
      this.trackData = { ...this.trackData, ...this.trackData.objectData };
      delete this.trackData.objectData;
      this.trackData.songVersion = this.trackData.version;
      const trackingData: any = {
        name: this.trackData.name,
        id: this.trackData._id || this.trackData.trackId,
        artists: this.trackData.artists,
        version: this.trackData.version,
        soId: this.trackData.soId,
        uri: this.trackData.uri,
        songVersion: this.trackData.songVersion || this.trackData.version,
      };
      if (this.trackTargetId()) {
        this.trackData.targetId = this.trackData.trackId;
        trackingData.targetId = this.trackData.trackId;
      }

      if (this.trackData.hasOwnProperty('playlistId')) {
        trackingData.playlistId = this.trackData.playlistId;
        trackingData.playlistName = this.trackData.playlistName;
      }
      if (!this.authenticationService.getCurrentUser()?.email.includes('sostereo')) {
        this.trackingService.track('Song Play', trackingData, {
          event_action: 'Song play',
          event_type: 'Song Play',
          element_type: 'Song',
          element_value: trackingData?.name,
          element_id: trackingData?.id,
          element_item_type: trackingData?.version,
          element_item_id: trackingData?.soId,
        });
      }
    }
  }

  trackTargetId(): boolean {
    const partRoute = this.router.url.split('/');
    return !partRoute.includes('rating');
  }

  generateWaveform(): void {
    this.controlIsReady = false;
    const headers = new Headers();
    headers.append('Cache-Control', 'no-cache, no-store, must-revalidate');
    headers.append('Pragma', 'no-cache');
    headers.append('Expires', '0');
    Promise.resolve(null).then(() => {
      this.wave = WaveSurfer.create({
        container: '#waveform',
        backend: 'MediaElement',
        waveColor:
          this.commonService.waveColor.getValue() || this.environment.waveColor || '#2ececb',
        height: 60,
        progressColor:
          this.commonService.waveColor.getValue() || this.environment.waveColor || '#2ececb',
        hideScrollbar: true,
        barGap: 2.5,
        barWidth: 1.5,
        barHeight: 0.6,
        fetchParams: { mode: 'no-cors', cache: 'no-store', headers },
      });
      const control = this.control;

      this.wave.on('loading', () => {
        console.log('waveform loading');
        control.loading = true;
      });

      this.wave.on('interaction', (event) => {
        console.log('waveform Interaction', event);
      });

      this.wave.on('redraw', () => {
        console.log('waveform redraw');
      });

      this.wave.on('audioprocess', (event) => {
        // console.log('waveform Audioprocess', event);
        control.loading = true;
        this.audioprocess = event;
        if (this.audioprocess !== 0) {
          control.loading = false;
        }
      });

      this.wave.on('decode', (e) => {
        console.log('waveform decode', e);
      });
      this.wave.on('load', (e) => {
        console.log('waveform load', e);
      });
      this.wave.on('ready', (duration) => {
        console.log('waveform Ready', duration);
        if (duration > 1) {
          this.wave.setOptions({ barAlign: this.track?.segmentsGroups ? 'bottom' : undefined });

          this.controlIsReady = true;
          control.duration = this.wave.getDuration();

          this.waveReady = true;
          if (this.autoPlay) {
            this.control.pause = true;
            this.control.play = false;
            this.playPromise(true);
          } else {
            setTimeout(() => {
              if (!this.wave.isPlaying()) {
                control.loading = false;
                control.play = true;
                control.pause = false;
                this.wave?.seekTo(0);
              }
              this.cdr.detectChanges();
            });
          }
          this.cdr.detectChanges();
        }
      });
      this.wave.on('play', () => {
        console.log('waveform Play');
        this.subscribe = this.source.subscribe(() => {
          control.currentTime = this.wave.getCurrentTime();
          control.duration = this.wave.getDuration();
          this.cdr.detectChanges();
        });
      });

      this.wave.on('destroy', () => {
        console.log('waveform destroy');
        if (this.wave) {
          this.wave.destroy();
        }
      });

      this.wave.on('finish', () => {
        console.log('waveform finish');
        control.play = true;
        control.pause = false;
        this.playerWidgetService.songFinished();
        this.cdr.detectChanges();
      });
      this.cdr.detectChanges();
    });
  }

  private playPromise(isReady?) {
    const promise = this.wave.play();
    if (promise !== undefined) {
      promise
        .then(() => {
          // Autoplay started!
          this.wave.play();
          this.playerWidgetService.songPlaying(this.track);
        })
        .catch(() => {
          // Autoplay was prevented.
          setTimeout(() => {
            if (!this.wave.isPlaying() && isReady) {
              this.control.loading = false;
              this.control.play = true;
              this.control.pause = false;
            }
            this.cdr.detectChanges();
          });
        });
    }
  }

  onPreviewPressed(): void {
    if (!this.wave && this.isBrowser) {
      this.generateWaveform();
    }
    this.cdr.detectChanges();
  }

  onPlayPressed(): void {
    console.log('waveform OnPlayPressed');
    this.control.pause = true;
    this.control.play = false;
    this.playPromise();
    this.playerWidgetService.songPlaying(this.track);
  }

  onPausePressed(): void {
    console.log('waveform OnPausePressed');
    this.control.pause = false;
    this.control.play = true;
    this.wave.pause();
    this.playerWidgetService.songPaused(this.track);
  }

  onClosePressed(): void {
    console.log('waveform OnClosePressed');
    this.control.pause = false;
    this.control.play = true;
    this.wave.stop();
    this.playerWidgetService.showPlayer = false;
    this.playerWidgetService.songPaused(this.track);
  }

  onStopPressed(): void {
    console.log('waveform OnStopPressed');
    this.wave.stop();
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    const target = event.target as HTMLElement;
    if (target.tagName.toUpperCase() === 'BODY') {
      switch (event.keyCode) {
        case 32:
          if (this.playerWidgetService.showPlayer) {
            if (this.playerWidgetService.playerActive) {
              this.onPausePressed();
            } else {
              this.onPlayPressed();
            }
          }
          break;
        case 37:
        case 71:
          if (this.playerWidgetService.playerActive) {
            this.wave.skip(-5);
          }
          break;
        case 39:
        case 186:
          if (this.playerWidgetService.playerActive) {
            this.wave.skip(5);
          }
          break;
      }
    }
  }

  public setVolume(value) {
    console.log('setVolume VALUE', value);
    this.volume = value;
    let sessionSettings = this.authenticationService.getUserSessionSettings();
    if (sessionSettings) {
      if (sessionSettings?.playerWidgetConfig) {
        sessionSettings.playerWidgetConfig.volume = this.volume;
      } else {
        sessionSettings.playerWidgetConfig = { volume: this.volume };
      }
    } else {
      sessionSettings = { playerWidgetConfig: { volume: this.volume } };
    }
    localStorage.setItem('SOSTEREO.sessionSettings', JSON.stringify(sessionSettings));
    this.wave.setVolume(this.volume);
  }

  public toggleMute() {
    if (this.volume === 0) {
      this.setVolume(this.lastVolume);
    } else {
      this.lastVolume = clone(this.volume);
      this.setVolume(0);
    }
  }

  showSongLyric() {
    if (
      (!this.router.routerState.snapshot.root.queryParams.qFields ||
        this.router.routerState.snapshot.root.queryParams.qFields !== 'lyric') &&
      !this.router.routerState.snapshot.root.queryParams.songId &&
      this.router.url.includes('/music-sync-licensing/')
    ) {
      let params: any = this.commonService.getQueryParamsFromUrl();
      params.lyrics = 'open';
      const urlTree = this.router.createUrlTree([], {
        queryParams: params,
        queryParamsHandling: 'merge',
      });
      this.location.go(urlTree.toString());
    }
    const currentTrack = this.playerWidgetService.track.objectData;
    this.playerService.lyric = this.playerService.getLyircsHiglights(currentTrack);
    this.playerService.showLyrics.next(true);
  }

  getShareLink() {
    let relativeUrl = '';
    const songSoId = this.getSongSoId();
    const versionId = this.trackData.trackId || this.trackData._id;
    if (!songSoId) {
      this.toastr.error('There was an error getting share link for this song');
      this.trackingService.track('Error copying Song link to clipboard at player', {
        songName: this.track,
        songId: this.track,
        element: 'Player widget',
      });
      return;
    }
    relativeUrl = `/music-sync-licensing/search?songId=${songSoId}&versionId=${versionId}`;
    this.clipBoardService.copy(`${window.location.hostname}${relativeUrl}`);
    this.toastr.info('Share link copied to clipboard');
    this.trackingService.track('Song link copied to clipboard', {
      songName: this.track,
      songId: this.track,
      element: 'Player widget',
    });
  }

  getSongSoId(): string {
    if (this.trackData.soId) {
      return this.trackData.soId;
    } else if (this.trackData.uri) {
      const songSoIdSplit = this.trackData.uri.split(':');
      const songSoId = songSoIdSplit[songSoIdSplit.length - 1].split('/')[0].toLowerCase();
      return songSoId;
    }
    return null;
  }

  downloadTrack(version?) {
    let songArtists = '';

    forEach(this.track.objectData.artists, (artist, i: Number) => {
      songArtists = songArtists + (i === 0 ? ' ' : ', ') + (artist.artistName || artist.name);
    });
    this.toastr.info('Downloading your song', '', {
      tapToDismiss: true,
      disableTimeOut: true,
      positionClass: 'download-song-toastr',
    });
    this.downloadService
      .downloadSingleSong(
        this.track.objectData,
        !version && this.track.url
          ? this.track.url
          : this.track.objectData.files
          ? this.track.objectData.files[version ? version : 'streaming'].url ||
            this.track.objectData.files[version ? version : 'streaming']
          : this.track.objectData.fileUrls[version ? version : 'streaming'].url ||
            this.track.objectData.fileUrls[version ? version : 'streaming'],
        this.track.objectData.songVersion,
        songArtists,
        version ? version : null,
      )
      .then(() => {
        this.toastr.clear();
        this.cdr.detectChanges();
      });
  }

  addTrackToPlaylist() {
    this.addToPlaylistModal.tracksInput = [this.track?.objectData];
    this.addToPlaylistModal.showModal();
  }

  public showInternalNotes(song) {
    this.songInternalNotesModal.showModal(song);
  }

  onAddToPlaylistSuccess(event) {
    const playlistSlug = event.playlist.slug;
    this.playerWidgetService.trackAddedToPlaylistEvent({
      type: 'existing',
      playlistId: event?.playlist.id || '',
    });
    if (
      this.router.url.includes(`music-sync-licensing/playlist`) &&
      this.router.url.includes(playlistSlug)
    ) {
      this.playerWidgetService.emitUpdatePlayer();
    }
  }

  onUpdateCommentRequest() {
    if (this.track.local) {
      this.setComments(JSON.parse(localStorage.getItem('SOSTEREO.submissionComments')));
    } else {
      this.commentsService
        .query({ playListId: this.track.tabId || this.track.objectData.playlistId })
        .subscribe(
          (res) => {
            this.setComments(res.data.items);
          },
          (err) => {
            this.toastr.error(err?.error?.message, 'There was an error retrieving comments');
            console.log(err);
          },
        );
    }
  }

  setComments(commentItems) {
    if (this.track) {
      this.playerWidgetService.updateCommentsEvent(commentItems);
      this.track.comments = commentItems[this.track.trackId]
        ? commentItems[this.track.trackId].times
        : [];
      this.track.comments = sortBy(this.track.comments, 'startTime');
    }
  }

  onSegmentRequest(event) {
    this.wave.play();
    this.wave.setTime(event.startTime);
  }

  hideCommentList() {
    if (this.commentList) {
      this.commentList.openComment = null;
      this.commentList.hideAllComments();
    }
  }

  showArtistCopyright() {
    //console.log(this.track, this.router.url);
    if (
      this.router.url === '/songs/list' &&
      (!this.track.objectData.hasOwnProperty('copyright') ||
        !this.track.objectData.hasOwnProperty('restriction'))
    ) {
      this.songService.get(this.track.trackId, {}).subscribe({
        next: (res) => {
          this.track.objectData.copyright = res.data.copyright;
          this.track.objectData.restriction = res.data.restriction;
          this.track.objectData.internalManager = res.data?.internalManager;
          this.openSongRightholdersInfoModal();
        },
        error: () => this.openSongRightholdersInfoModal(),
      });
    } else {
      this.openSongRightholdersInfoModal();
    }
  }

  private openSongRightholdersInfoModal() {
    this.songRightholdersInfoModal.showModal({
      artist:
        this.track.objectData.selectedArtist ||
        (this.track.objectData.artists
          ? this.track.objectData.artists[0]
          : this.track.objectData.artistName),
      songCopyright:
        this.track.objectData.fileRepresentedBy ||
        this.track.objectData.representedBy ||
        this.track.objectData.copyright,
      songRestriction: this.track.objectData.restriction,
      songRestrictions: this.track.objectData.restrictions,
      internalManager: this.track?.objectData?.internalManager,
      songId: this.track?.objectData?._id || this.track?.objectData?.trackId,
    });
  }

  public isAllowed(allowed, disallowed?) {
    return this.commonService.isAllowed(allowed, disallowed);
  }

  public showPlayListBySong() {
    const name = this.trackData.name;
    const songSoId = this.getSongSoId();
    if (songSoId) {
      return this.router.navigateByUrl(
        `/music-sync-licensing/edit-playlist?songFeaturedIn=${songSoId}&name=${name}`,
      );
    } else {
      this.toastr.error('There was an error getting playlist link for this song');
    }
  }

  public login() {
    const url = window.location.pathname + window.location.search;
    this.router.navigate(['/login'], { queryParams: { returnUrl: url } });
  }

  public signUp() {
    const url = window.location.pathname + window.location.search;
    this.router.navigate(['/signup'], { queryParams: { returnUrl: url } });
  }

  isAuthenticated() {
    return (
      this.authenticationService.getCurrentUser() && !this.authenticationService.isTokenExpired()
    );
  }

  showLicenseButton(): boolean {
    const userAuthenticated = this.isAuthenticated();
    return (
      !this.track?.hideOptions &&
      (!userAuthenticated || (userAuthenticated && this.isAllowed(['ListTransactions'])))
    );
  }

  clearInfoParams() {
    if (this.router.url.includes('music-sync-licensing')) {
      let queryParams: any = cloneDeep(this.commonService.getQueryParamsFromUrl());
      if (queryParams.download || queryParams.identity) {
        queryParams.download = null;
        queryParams.identity = null;
        const urlI = this.router.createUrlTree([], { queryParams: queryParams }).toString();
        this.location.go(urlI);
      }
    }
  }

  setNewTrack(track: any) {
    if (!this.commonService.isAllowed(['*', 'ListTiers'])) {
      this.playerWidgetService.setNewTrack(track);
      this.clearInfoParams();
      return;
    }
    if (
      (track.objectData?.isOneStop == undefined ||
        track.objectData?.tierHasWarning == undefined ||
        track.objectData?.publicAccess == undefined) &&
      !track.objectData?.signedUrl
    ) {
      this.songService.get(track.id || track.trackId, {}).subscribe(
        (response) => {
          this.commonService.getTierList().subscribe((tiers) => {
            const tier = tiers?.find((tierIte) => tierIte._id == response.data.tierId);
            this.playerWidgetService.setNewTrack({
              ...track,
              objectData: {
                ...response.data,
                publicAccess: tier?.publicAccess,
                tierHasWarning: tier?.hasWarning,
              },
            });
            this.clearInfoParams();
          });
        },
        (error) => {
          console.error(error.message);
          this.trackingService.track('Error getSongById', { error: error.message });
          this.playerWidgetService.setNewTrack(track);
          this.clearInfoParams();
        },
      );
    } else {
      this.playerWidgetService.setNewTrack(track);
      this.clearInfoParams();
      return;
    }
  }

  public closeNewSegment($event) {
    this.playerWidgetService.showSelectSegment = $event;
    this.showSelectSegment = $event;
  }

  public openPlacementModal() {
    this.catalogOwnerCommunicationModal.showModal(undefined, undefined, this.trackData, 'songs');
  }
}
