import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommentsService } from 'sostereo-services';
import { ToastrService } from 'ngx-toastr';
import { sortBy, findIndex, isNumber, isEmpty } from 'lodash-es';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss'],
})
export class CommentListComponent implements OnInit, OnChanges {
  @Input() loggedUser;
  @Input() commentItems: any[];
  @Input() duration: any;
  @Input() track;
  @Output() segmentRequest = new EventEmitter();
  @Output() updateCommentRequest = new EventEmitter();
  @Output() loginRequest = new EventEmitter();
  private message = '';
  public openComment: any;

  constructor(
    private commentsService: CommentsService,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.commentItems) {
      this.commentItems = sortBy(this.commentItems, 'startTime');
      this.setCommentsPosition();
      if (this.openComment) {
        let found = false;
        const prevOpenedComment = this.commentItems.find((commentItem) => {
          commentItem.comments.forEach((comment1) => {
            this.openComment.comments.forEach((comment2) => {
              if (comment1.id === comment2.id) {
                found = true;
              }
            });
          });
          if (found) {
            return commentItem;
          } else {
            return null;
          }
        });
        if (prevOpenedComment) {
          this.toggleComments(prevOpenedComment);
        }
      }
    }
  }

  setCommentsPosition() {
    const waveFormContainer = document.getElementById('waveform-container');
    this.commentItems.forEach((commentItem) => {
      commentItem.showComments = false;
      commentItem.left = (commentItem.startTime * waveFormContainer.offsetWidth) / this.duration;
      commentItem.left = Math.trunc(commentItem.left);
      let rightPosition = (commentItem.endTime * waveFormContainer.offsetWidth) / this.duration;
      rightPosition = Math.trunc(rightPosition);
      commentItem.width = rightPosition - commentItem.left;
    });
  }

  saveComment(comment) {
    const tempComment: any = {
      startTime: comment.startTime,
      endTime: comment.endTime,
      userNickname: this.loggedUser ? this.loggedUser.nickname : 'me',
      playListId: this.track.tabId || this.track.objectData.playlistId,
      trackId: this.track.trackId,
      message: this.message,
    };
    if (this.track.local) {
      const localComments = JSON.parse(localStorage.getItem('SOSTEREO.submissionComments'));
      const commentIndex = findIndex(
        localComments[this.track.trackId].times,
        (commentItem: any) =>
          commentItem.startTime === tempComment.startTime &&
          commentItem.endTime === tempComment.endTime,
      );
      if (isNumber(commentIndex)) {
        tempComment.id =
          tempComment.startTime +
          tempComment.endTime +
          localComments[this.track.trackId].times[commentIndex].comments.length;
        localComments[this.track.trackId].times[commentIndex].comments.push(tempComment);
        localStorage.setItem('SOSTEREO.submissionComments', JSON.stringify(localComments));
        this.toastr.success('Comment submitted successfully');
        this.message = '';
        this.updateCommentRequest.emit();
      } else {
        this.toastr.error('There was an error submitting your comment');
      }
    } else {
      this.commentsService.post(tempComment).subscribe({
        next: () => {
          this.toastr.success('Comment submitted successfully');
          this.message = '';
          this.updateCommentRequest.emit();
        },
        error: (err) => {
          this.toastr.error(err?.error?.message, 'There was an error submitting your comment');
          console.log(err);
        },
      });
    }
  }

  login() {
    this.loginRequest.emit();
  }

  toggleComments(commentItem) {
    this.commentItems.forEach((c) => {
      if (JSON.stringify(c) !== JSON.stringify(commentItem)) {
        c.showComments = false;
      }
    });
    commentItem.showComments = !commentItem.showComments;
    this.openComment = commentItem.showComments ? commentItem : null;
  }

  hideAllComments() {
    this.commentItems.forEach((c) => {
      c.showComments = false;
    });
  }

  closeComments(commentItem) {
    commentItem.showComments = false;
  }

  playSegment(commentItem) {
    const segment = {
      startTime: parseInt(commentItem.startTime, 10),
      endTime: parseInt(commentItem.endTime, 10),
    };
    this.segmentRequest.emit(segment);
  }

  goToNextComment(commentIndex) {
    const comment = this.commentItems[commentIndex + 1]
      ? this.commentItems[commentIndex + 1]
      : this.commentItems[0];
    this.toggleComments(comment);
    this.playSegment(comment);
  }

  toggleMoreOptions(comment) {
    comment.showMoreOptions = !comment.showMoreOptions;
  }

  deleteComment(commentsList, commentIndex) {
    if (this.track.local) {
      const localComments = JSON.parse(localStorage.getItem('SOSTEREO.submissionComments'));
      const localCommentIndex = findIndex(
        localComments[this.track.trackId].times,
        (commentItem: any) =>
          commentItem.startTime === commentsList.startTime &&
          commentItem.endTime === commentsList.endTime,
      );
      if (isNumber(localCommentIndex)) {
        if (localComments[this.track.trackId].times[localCommentIndex].comments.length === 1) {
          localComments[this.track.trackId].times.splice(localCommentIndex, 1);
          if (isEmpty(localComments[this.track.trackId].times)) {
            delete localComments[this.track.trackId];
          }
        } else {
          localComments[this.track.trackId].times[localCommentIndex].comments.splice(
            commentIndex,
            1,
          );
        }
        localStorage.setItem('SOSTEREO.submissionComments', JSON.stringify(localComments));
        this.toastr.success('Comment deleted successfully');
        this.updateCommentRequest.emit();
      } else {
        this.toastr.error('There was an error deleting your comment');
      }
    } else {
      this.commentsService.delete(commentsList.comments[commentIndex].id).subscribe({
        next: () => {
          this.toastr.success('Comment deleted successfully');
          this.updateCommentRequest.emit();
        },
        error: (err) => {
          this.toastr.error(err?.error?.message, 'There was an error deleting your comment');
          console.log(err);
        },
      });
    }
  }

  onKeydown(event) {
    event.stopPropagation();
  }
}
